import React from 'react';
import { ModalWithStepsProvider } from '../../../contexts/ModalWithStepsContext';
import CreateProductForm from './CreateProductForm';

const CreateProduct = () => {
    return (
        <ModalWithStepsProvider>
            <CreateProductForm />
        </ModalWithStepsProvider>
    )
}
 
export default CreateProduct;