import React, { useState, useEffect, useRef } from 'react';
import { Form } from "react-bootstrap";
// hooks
import { useFonts } from '../../../contexts/FontsContext';

const SelectFontFamily = ({ handleFontChange, activeCanvasObject }) => {
    const { fonts } = useFonts();
    const firstRender = useRef(true);
    const [availableFonts, setAvailableFonts] = useState(['arial', 'consolas', 'fantasy', 'verdana']);

    // join default names with fetched fonts
    useEffect(() => {
        if(Object.keys(fonts).length && firstRender.current){
            const fontNames = Object.keys(fonts);

            setAvailableFonts([
                ...availableFonts,
                ...fontNames
            ].sort());

            firstRender.current = false;
        }
    }, [fonts]);

    const customOnChangeHandler = ev => {
        const fontSelected = ev.target.value;
        const f = fonts[fontSelected];

        if (f) {
            handleFontChange(fontSelected, f.weights[0]);
        } else {
            handleFontChange(fontSelected);
        }
    }

    return (
        <Form.Group className="mb-3">
            <Form.Select
                name="fontFamily"
                onChange={customOnChangeHandler}
                value={activeCanvasObject.fontFamily}
                style={{ fontFamily: activeCanvasObject.fontFamily, fontSize: '20px' }}
            >
                {availableFonts.map((font, idx) => (
                    <option key={idx} value={font} style={{ fontFamily: font }}>
                        {font}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );
}

export default SelectFontFamily;