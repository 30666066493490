import React, { useEffect, useState } from "react";
import { Form, Image } from "react-bootstrap";
// image
import imageIcon from '../../assets/img/icons/img-icon.svg';

function ImageForm({
  setImageFile,
  pageName,
  imageUrl = "",
  watermarks = [],
  selectedWatermark,
  onWatermarkSelect = () => {},
}) {
  const [preview, setPreview] = useState(imageUrl || null);

  const isSavedImage = typeof imageUrl === "string"

  useEffect(() => {
    if (!isSavedImage) {
      return
    }
    setPreview(imageUrl);
  }, [imageUrl, isSavedImage]);

  const handleChange = (ev) => {
    const file = ev.target.files[0];
    if (!file) {
      setPreview(null);
      setImageFile(null);
      return;
    }

    if (preview) URL.revokeObjectURL(preview);

    const previewUrl = URL.createObjectURL(file);
    setPreview(previewUrl);
    setImageFile(file);
  };

  return (
    <div>
      <Form.Group className="mb-3">
        <div className="d-flex align-items-center">
            Image
            <img src={imageIcon} alt="icon image" style={{ width: '16px' }} className="m-2" />
        </div>
        <Form.Control
          type="file"
          name="image"
          onChange={handleChange}
          accept="image/*"
        />
      </Form.Group>

      {preview && (
        <div className="d-flex">
          <Image src={preview} width={350} />
          
            <div className="d-flex overflow-auto ms-4">
              {watermarks.map((item, index) => (
                <div
                  key={item._id}
                  style={{
                    cursor: "pointer",
                    borderWidth: 2,
                    borderStyle: "solid",
                    alignSelf: "flex-start",
                  }}
                  onClick={() => onWatermarkSelect(item._id)}
                  className={`border-${
                    selectedWatermark === item._id ? "success" : "secondary"
                  } rounded p-1 m-1`}
                >
                  <Image src={item.path} width={130} />
                  <div className="text-center p-1">{index + 1}</div>
                  <div className="text-center p-1">{item.originalName?.split('.')[0]}</div>
                </div>
              ))}
            </div>
          
        </div>
      )}
    </div>
  );
}

export default ImageForm;
