import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// request 
import categoriesRequests from '../../../../request/categories';
import productRequests from '../../../../request/products';
// custom hooks
import { useIsMounted } from '../../../../hooks/useIsMounted';
// icon
import closeIcon from '../../../../assets/img/icons/close-icon.svg';
// custom components
import SongItem from './songItem';

import './style/songsList.css';

const SongsList = ({ purchasedSongData, productId, onSelectSong }) => {
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [songs, setSongs] = useState([]);
    const [selectedSongId, setSelectedSongId] = useState('');
    const [selectedSongName, setSelectedSongName] = useState('');
    const [restartAnimation, setRestartAnimation] = useState(1);
    const isMounted = useIsMounted();
    const { pathname } = useLocation();

    // reset values
    const handleResetValues = () => {
        setSelectedSongId('');
        setSelectedSongName('');
        onSelectSong(null);
    }

    useEffect(() => {
        if(!productId) return;

        productRequests.getProductAsAdmin(productId)
            .then( productData => {
                const categoryIdObj = [...(productData.categoriesIds || [])].pop();

                categoriesRequests.getCategorySongs(categoryIdObj._id)
                    .then( _songs => {
                        if(isMounted.current){
                            setSongs(_songs);
                        }
                    }) 
            })
            .catch(console.log)
            .finally(() => {
                if(isMounted.current) setLoading(false);
            })
    }, [productId, isMounted]);


    const handleChange = (songObj) => {
        if(songObj){
            setSelectedSongId(songObj._id);
            setSelectedSongName(songObj.filename);
            onSelectSong(songObj);
        }else{
            handleResetValues();
        }
    }

    const handleShowList = (ev) => {
        const { checked } = ev.target;
        setCheckboxChecked(checked);
        setShowPopup(checked);
        
        if(!checked && selectedSongId){
            handleResetValues();
        }
    }


    const closePopup = () => {
        setShowPopup(false);
        if(!selectedSongId) setCheckboxChecked(false);
        const audioElmts = document.querySelectorAll('.songs-container audio');
        if(audioElmts.length){
            audioElmts.forEach( audio => {
                audio.pause();
                audio.currentTime = 0;
            });
        } 
        // make sure the animation start from beggining 
        setRestartAnimation(restartAnimation + 1);
    }

    if (loading || !songs.length) return null;

    return (
        <>
            <div className='hrLine'/>

            <div className='songs-container editor-padding'>
                <div className='pd-checkbox'>

                    <div>
                        <input 
                            onChange={handleShowList} 
                            id="add-music-check" 
                            type="checkbox" 
                            checked={checkboxChecked}
                        />
                        <label htmlFor="add-music-check">Add Music</label>
                    </div>
                </div>

                <div className="audios-container" style={{ display: showPopup ? 'block' : 'none' }} >
                    
                    <div className="audios-container-close">
                        <div onClick={closePopup} className="audios-container-close-btn">
                            <img src={closeIcon} alt="close icon" />
                        </div>
                    </div>

                    <div className="audios-container-song-list">
                        {
                            songs.map((songObj) => {
                                return (
                                    <SongItem 
                                        key={songObj._id} 
                                        songObj={songObj} 
                                        selectedSongId={selectedSongId}
                                        handleChange={handleChange} 
                                        restartAnimation={restartAnimation}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                
            </div>
                            
            {
                selectedSongName && (
                    <div className="selected-song">
                        <div className="selected-song-name">{selectedSongName}.mp3</div>
                        <span>Has been added</span>
                    </div>
                )
            }
        </>
    );
}
 
export default SongsList;