import { useCallback, useState } from 'react';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
// custom components
import CategoryListItem from './categoryListItem';
// request
import categoriesRequests from '../../../request/categories';

let requestTimeout;


function SortableCategoryContainer({categoriesArray}) {
    const [categories, setCategories] = useState(categoriesArray);

    const handleMoveItemDragged = useCallback((dragIndex, hoverIndex) => {
        if (!categories.length) return;

        setCategories((prevCategories) => {
            const itemsUpdated = update(prevCategories, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCategories[dragIndex]],
                ],
            })

            return itemsUpdated;
        });

        // ensure update backend once state finish updating.
        clearTimeout(requestTimeout);
        requestTimeout = setTimeout(() => {
            console.log('inside timeout');
            setCategories(categoriesState => { // using setState callback to obtain current state after the update
                const categoriesNewOrder = categoriesState.map( (c, idx) => ({ ...c, orderIndex: idx }));

                categoriesRequests.updateCategoriesOrder(categoriesNewOrder)
                    .then(() => toast.success("Saved", { position: "bottom-center" }))
                    .catch(() => toast.error("Can't update product order", { position: "bottom-center" }))

                return [...categoriesState];
            })
        }, 2000);
    }, []);

    return (
        <DndProvider backend={HTML5Backend}>
            {
                categories.map((c, i) => (
                    <div key={c._id}>
                        <CategoryListItem
                            index={i}
                            handleMoveItemDragged={handleMoveItemDragged}
                            categoryName={c.name}
                            categoryId={c._id}
                        />
                    </div>
                ))
            }
        </DndProvider>
    )
}

export default SortableCategoryContainer;