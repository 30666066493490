import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { Spinner } from 'react-bootstrap';
// requests
import categoriesRequests from '../../../request/categories';
// custom components
import { SimplePageTitle } from '../../../components/titles/pageTitles';
import SimpleButtonLink from '../../../components/Links/simpleButtonLink';
import SortableCategoryList from './sortableCategoryContainer';
// hooks
import { useIsMounted } from '../../../hooks/useIsMounted';



function CategoriesList() {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const isMounted = useIsMounted();


    useEffect(() => {
        categoriesRequests.getCategories()
            .then( _categories => {
                if(isMounted.current) setCategories(_categories);
            })
            .finally(() => {
                if(isMounted.current) setLoading(false);
            })
    }, []);

    return (
        <>
            <Helmet title="Principal categories" />

            <div className="d-flex justify-content-between mb-3">
                <SimplePageTitle>Main Categories list</SimplePageTitle>

                <SimpleButtonLink 
                    to="/pages/createCategory"
                    title="Add new category"
                />
            </div>
            
            {
                loading ? (
                    <Spinner animation='border' />
                ) : (
                    <SortableCategoryList 
                        categoriesArray={categories} 
                    />
                )
            }
        </>
    )
}

export default CategoriesList;