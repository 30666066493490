import ajax from './ajax';
import { API_BASE_URL } from '../config';

const categoriesRequests = {};


categoriesRequests.createCategory = async function ({
    parentCategoryId,
    name,
    color,
    backgroundColor,
    imageId,
    musicIds
}) {
    const url = `${API_BASE_URL}/categories/CreateCategory`;

    const createdCategory = await ajax.post(
        url,
        {
            parentCategoryId,
            name,
            color,
            backgroundColor,
            imageId,
            musicIds
        },
        { token: true }
    );
    return createdCategory;
}



categoriesRequests.updateCategory = async function (body) {
    const url = `${API_BASE_URL}/categories/UpdateCategory`;

    const updatedCategory = await ajax.post(url, body, { token: true });
    return updatedCategory;
}


categoriesRequests.updateCategoriesOrder = async function (categoriesArray) {
    const url = `${API_BASE_URL}/categories/UpdateCategoriesOrder`;

    const updated = await ajax.post(url, { categoriesArray }, { token: true });
    return updated;
}


categoriesRequests.getCategories = async function () {
    const url = `${API_BASE_URL}/categories/Categories`;

    const categories = await ajax.get(url);
    return categories;
}


categoriesRequests.getCategoriesAndSubCategoriesSimple = async function () {
    const url = `${API_BASE_URL}/categories/GetCategoriesAndSubCategoriesSimple`;

    const categories = await ajax.get(url, { token: true });
    return categories;
}


categoriesRequests.getCategory = async function (categoryId) {
    const url = `${API_BASE_URL}/categories/Category/${categoryId}`;

    const category = await ajax.get(url);
    return category;
}


categoriesRequests.getAllCategoriesAndSubCategories = async function () {
    const url = `${API_BASE_URL}/categories/GetAllCategoriesAndSubCategories`;

    const data = await ajax.get(url);
    return data;
}


categoriesRequests.deleteCategory = async function (categoryId) {
    const url = `${API_BASE_URL}/categories/DeleteCategory`;

    const deleted = await ajax.post(url, { _id: categoryId }, { token: true });
    return deleted;
}


categoriesRequests.getCategorySongs = async function (categoryId) {
    const url = `${API_BASE_URL}/categories/GetCategorySongs/${categoryId}`;

    const songs = await ajax.get(url);
    return songs;
}

categoriesRequests.deleteCategorySong = async function (categoryId, songId) {
    const url = `${API_BASE_URL}/categories/DeleteCategorySong`;

    const deleted = await ajax.post(url, { categoryId, songId }, { token: true });
    return deleted;
}


export default categoriesRequests;
