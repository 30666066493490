import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useParams } from 'react-router-dom';
// request
import categoriesRequests from "../../../request/categories";
// custom hook
import { useIsMounted } from "../../../hooks/useIsMounted";


function ParentCategorySelect({ handleChange, parentCategoryId = '' }) {
	const [ loadingCategories, setLoadingCategories ] = useState(true);
	const [ categories, setCategories ] = useState([]);

    const isMounted = useIsMounted();
    const { categoryId } = useParams();

	useEffect(() => {
        categoriesRequests.getCategories()
            .then( cateArr => {
                if(isMounted.current){
                    // prevent parent category loop
                    let ids = [categoryId];

                    for(let c of cateArr){
                        if( ids.includes(c.parentCategoryId)){
                            ids.push(c._id);
                        }
                    }
                    let cArr = cateArr.filter( c => !ids.includes(c._id) );

                    setCategories(cArr);
                    setLoadingCategories(false);
                }
            })
	}, []);


	return (
		<Form.Group className="mb-3">
            <Form.Label>Parent category</Form.Label>
            { loadingCategories &&  <Spinner animation="border" size='sm' style={{marginLeft: '15px'}} /> }
            
            <Form.Select 
                { ... ( parentCategoryId ? { value: parentCategoryId } : { defaultValue: '' } ) }
                name="parentCategoryId" 
                onChange={handleChange}
            >
                <option value="">None</option>
                {
                    categories.map( (c, idx) => (
                        <option  
                            key={idx} 
                            value={c._id}
                        >
                                {c.name}
                        </option>
                    ))
                }
            </Form.Select>
        </Form.Group>
	);
}

export default ParentCategorySelect;
