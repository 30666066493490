import { Menu, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import HrLine from './hrLine';
import 'antd/lib/dropdown/style/css';

const menu = (
    <Menu>
        <Menu.Item key="0">100%</Menu.Item>
        <Menu.Item key="1">75%</Menu.Item>
        <Menu.Item key="2">50%</Menu.Item>
        <Menu.Item key="3">25%</Menu.Item>
    </Menu>
);
 

const ScalePercentage = () => {
    return (
        <>
            <div className="editor-padding d-flex justify-content-center pt-0">
                <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        100% <FontAwesomeIcon icon={faChevronDown} />
                    </a>
                </Dropdown>
            </div>

            <HrLine />
        </>
    );
}
 
export default ScalePercentage;