import React, { useEffect, useState } from "react";
import { Popconfirm } from "antd";
import { Button, Spinner, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { SimplePageTitle } from "../../../components/titles/pageTitles";
import autofillableFieldsRequests from "../../../request/autofillableFields";
import AutofillableFieldForm from "./AutofillableFieldForm";
import 'antd/lib/popconfirm/style/css';

const AutofillableFields = () => {
  const [fields, setFields] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemToEdit, setItemToEdit] = useState();

  useEffect(() => {
    autofillableFieldsRequests
      .getAutofillableFields()
      .then(setFields)
      .finally(() => setLoading(false));
  }, []);

  const closeModal = () => {
    setShow(false);
    setItemToEdit(undefined);
  };

  const onSubmit = async ({ name, category }) => {
    if (itemToEdit?._id) {
      const updatedItem = await autofillableFieldsRequests.updateAutofillableField(itemToEdit._id, {
        name,
        category,
      });
      setFields(
        fields.map((item) => (item._id === itemToEdit._id ? updatedItem : item))
      );
    } else {
      const newItem = await autofillableFieldsRequests.createAutofillableField({ name, category });
      setFields([...fields, newItem]);
    }
    closeModal();
  };

  const handleItemEdit = (item) => {
    setItemToEdit(item);
    setShow(true);
  };

  const handleItemDelete = async (id) => {
    await autofillableFieldsRequests.deleteAutofillableField(id);
    setFields(fields.filter(item => item._id !== id));
  };

  return (
    <div>
      <Helmet title="Autofillable Fields" />
      <AutofillableFieldForm
        title={`${itemToEdit?._id ? "Edit a" : "Add new"} field`}
        onOk={onSubmit}
        show={show}
        onHide={closeModal}
        initialValues={itemToEdit}
      />
      <div className="d-flex justify-content-between mb-2">
        <SimplePageTitle>Autofillable Fields List</SimplePageTitle>

        <Button size="lg" onClick={() => setShow(true)}>
          Add new field
        </Button>
      </div>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Field name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((item, index) => (
              <tr key={item._id}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  <Button className="me-2" onClick={() => handleItemEdit(item)}>
                    Edit
                  </Button>
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => handleItemDelete(item._id)}
                  >
                    <Button variant="danger">Delete</Button>
                  </Popconfirm>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default AutofillableFields;
