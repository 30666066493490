import React, { useState } from 'react';
import miscellaneousRequest from '../../../request/miscellaneous';
import { ButtonGroup } from 'react-bootstrap'
import {Button} from 'antd';
import { DeleteFilled, CheckOutlined } from '@ant-design/icons';
import './style/watermarkItem.css';

const WatermarkItem = ({ path, originalName, onDelete, isDefault, setDefault, _id }) => {
    const [loading, setLoading] = useState(false);

    const handleSetDefault = () => {
        setLoading(true);
        miscellaneousRequest.setDefaultWatermarkId(_id)
            .then(() => setDefault(_id))
            .catch( err => console.error("Can't set to default", err))
            .finally(() => setLoading(false));
    }                

    return (
        <div className={`watermark-item-wrapper ${isDefault ? 'default' : ''}`} >
            <img src={path} alt={originalName} />
            <div>
                <div>{originalName}</div>
                <ButtonGroup className='w-btns'>
                    <Button 
                        onClick={handleSetDefault}
                        loading={loading}
                        icon={isDefault ? <CheckOutlined/> : null}
                        type="primary">
                            {isDefault ? 'Default' : 'Set Default'}
                    </Button>
                    <Button 
                        disabled={isDefault}
                        onClick={() => onDelete(_id)} 
                        danger
                        type="primary"
                        icon={<DeleteFilled/>} 
                    />
                </ButtonGroup>
            </div>
        </div>
    );
}
 
export default WatermarkItem;