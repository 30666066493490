import ajax from './ajax';
import { API_BASE_URL, WP_API_BASE_URL } from '../config';
// utils
import { JSON2FormData } from '../utils/formData';

const customerPurchasesRequests = {};

customerPurchasesRequests.getCustomerPurchasesByEmail = async function ({ customerEmail }) {
    try {
        const url = `${API_BASE_URL}/purchases/GetCustomerPurchases?customerEmail=${customerEmail}`;
        const purchases = await ajax.get(url, { token: true });

        return purchases;
    } catch (err) {
        console.log('Error getting customer orders by email');
        return null;
    }
}


customerPurchasesRequests.getWooOrderItem = async function ({ wooCartItemKey, wooOrderId, wooOrderItemId }) {
    const formData = JSON2FormData({
        woo_order_id: wooOrderId,
        woo_order_item_id: wooOrderItemId,
        woo_cart_item_key: wooCartItemKey
    });

    try {
        const response = await fetch(`${WP_API_BASE_URL}/get_order_item.php`, {
            method: 'POST',
            body: formData
        });
        const json = await response.json();
        return json.data;

    } catch (err) {
        console.log('Error deleting product in wordpress');
        return null;
    }
}

export default customerPurchasesRequests;