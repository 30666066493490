import React from "react";
import { Dropdown } from "react-bootstrap";
import { Settings } from "react-feather";
import avatar from "../../assets/img/avatars/default.jpg";
// hooks
import useAuth from '../../hooks/useAuth';


const NavbarUser = () => {
  const { authState, signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
  }

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={avatar}
            className="avatar img-fluid rounded-circle me-1"
            alt="User"
          />
          <span className="text-dark">{authState.user?.username}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
