import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import FullScreenLoader from "../components/Loaders/fullScreenLoader";
// request
import authRequests from "../request/auth";
// utils
import { setToken, getToken } from '../utils/token';


const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [authState, setAuthState] = useState({
    tokenVerified: false,
    user: {}
  });
  const [isVerifying, setIsVerifying] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const resetState = () => {
    setAuthState({
      tokenVerified: false,
      user: {}
    });
  }


  const signIn = async (email, password) => {
    try {
      const sessionData = await authRequests.login(email, password);
      // save token in localStorage
      setToken(sessionData.token);

      setAuthState({
        tokenVerified: true,
        user: sessionData.user
      });
    } catch (err) {
      setToken(null);

      setAuthState({
        tokenVerified: false,
        user: null
      });
    }
  }


  const signOut = async () => {
    setToken('');
    resetState();
    navigate('/');
  };


  useEffect(() => {
    const verifyToken = async () => {
      if (authState.tokenVerified || location.pathname === '/') {
        if (authState.tokenVerified && getToken()) {
          return;
        }
      }

      setIsVerifying(true);

      authRequests.verifyToken()
        .then(userDecodedData => {
          setAuthState(prev => ({
            ...prev,
            tokenVerified: !!userDecodedData,
            user: userDecodedData || {}
          }))
          setIsVerifying(false);
        })
        .catch(err => {
          resetState();
          setIsVerifying(false);
          navigate('/');
        })
    }

    verifyToken();
  }, [location.pathname]);


  return (
    <AuthContext.Provider
      value={{
        authState,
        signIn,
        signOut,
      }}
    >
      {
        (isVerifying) ? (
          <FullScreenLoader />
        ) : (
          children
        )
      }
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
