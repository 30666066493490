import React, { useState, useEffect } from "react";
import { Spinner, Form } from "react-bootstrap";
import { Cascader } from "antd";
// custom hooks
import { useIsMounted } from '../../../hooks/useIsMounted';
// request
import categoriesRequests from "../../../request/categories";

import 'antd/lib/cascader/style/css.js';

const categoriesRecursiveParser = (categories, arrToFill) => {
    if(!categories?.length) return arrToFill;

    for(let c of categories){
        let { _id, name, subCategories } = c;
        arrToFill.push({
            value: _id,
            label: name,
            children: subCategories.length ? (
                categoriesRecursiveParser(subCategories, []) 
            ) : ([])
        })
    }

    return arrToFill;
}


const SelectProductCategory = ({ onSelectCategory, defaultValues = [] }) => {
    const isMounted = useIsMounted();
    const [parsedCategories, setParsedCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const handleChange = data => {
        onSelectCategory(data);
    }

    useEffect(() => {
        categoriesRequests.getAllCategoriesAndSubCategories()
            .then(({ categories }) => {
                if(isMounted.current){
                    const parsed = categoriesRecursiveParser(categories, []);
                    setParsedCategories(parsed);
                }
            }).finally(() => {
                if(isMounted.current){
                    setLoading(false);
                }
            })
    }, []);
 
    return (
        <div className="categories-container">
            <Form.Group className="mb-3 d-flex flex-column">
                <Form.Label>Select specific category</Form.Label>
                {
                    loading ? (
                        <Spinner size='sm' animation="border" style={{marginLeft: '10px'}} />
                    ) : (
                        <Cascader 
                            options={parsedCategories} 
                            onChange={handleChange} 
                            placeholder="Select" 
                            defaultValue={defaultValues}
                            multiple
                            changeOnSelect
                        />
                    )
                }
            </Form.Group>
        </div>
    );
}

export default SelectProductCategory;