import React, { useRef } from 'react';
import { fabric } from 'fabric';
import HrLine from "./hrLine";
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
// request
import productRequests from '../../../../request/products';
import filesRequests from '../../../../request/files';



const ChangeBackground = ({ canvas, productId }) => {
    const inputFileRef = useRef();

    const changeCanvasBackground = (imageUrl) => {
        if(!canvas) return;

        return new Promise((resolve) => {
            canvas.backgroundImage._removeCacheCanvas();

            fabric.Image.fromURL(imageUrl, function (img) {
                // change canvas height relative to image height
                const scaleFactor = img.width / canvas.width;
                const newCanvasHeight = img.height / scaleFactor;
                canvas.setDimensions({ width: canvas.width, height: newCanvasHeight });
    
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                    scaleX: canvas.width / img.width,
                    scaleY: canvas.height / img.height
                });

                resolve();
            }, {
                crossOrigin: "Anonymous"
            });
        });
    }


    const changeProductFile = async (ev) => {
        const { files } = ev.target;
        if(files.length){
            Swal.fire({
                title: 'changing background',
                text: 'please wait a moment',
                showConfirmButton: false
            });
            const beforeChangeProduct = await productRequests.getProductAsAdmin(productId);            
            const imageSaved = await filesRequests.uploadProductImage(files[0], beforeChangeProduct.filesId.watermarkId || 'default');
            // update in mongodb
            const updatedProduct = await productRequests.updateProduct({
                _id: productId,
                filesId: imageSaved._id
            });
            // update in wordpress
            await productRequests.wpUpsertProduct({
                woo_product_id: updatedProduct.wooProductId,
                customizer_product_id: updatedProduct._id,
                name: updatedProduct.name,
                price: updatedProduct.price,
                status: updatedProduct.status,
                image_url: updatedProduct.filesId.pathWithWatermark
            });

            await changeCanvasBackground(updatedProduct.filesId.path);

            Swal.close();
        }
    }

    const triggerInputFile = () => {
        inputFileRef.current.click();
    }

    return (
        <>
            <div className="editor-padding">
                <div className="editor-label-txt">Background</div>
                
                <div className="d-flex justify-content-center mt-2">
                    <Button 
                        size="lg" 
                        style={{ backgroundColor: '#ebebeb', color: 'black', outline: 'none', width: '100%', border: 'none' }}
                        onClick={triggerInputFile}
                    >
                        Change Background
                    </Button>
                </div>
                {/* hidden input */}
                <input 
                    type="file" 
                    ref={inputFileRef} 
                    onChange={changeProductFile} 
                    style={{ display: 'none' }}
                    accept="image/*"
                />
            </div>

            <HrLine/>
        </>
    );
}
 
export default ChangeBackground;