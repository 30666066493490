import React from 'react';
import { Form } from 'react-bootstrap';
import HrLine from './hrLine';

const RotateText = ({ onChange, activeCanvasObject }) => {
    const { angle } = activeCanvasObject;

    return (
        <>
            <div className="editor-padding">
                <div className="editor-label-txt">Rotate</div>

                <Form.Control 
                    type="number"
                    value={Math.round(angle || 0)}
                    onChange={onChange}
                    name="angle"
                    autoComplete="off"
                />
            </div>

            <HrLine />
        </>
    );
}
 
export default RotateText;