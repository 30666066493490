import { useState, useLayoutEffect } from 'react';
import availableFields from '../availableFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import './style/changeLabelDropdown.css';

const ChangeLabelDropdown = ({ tempId, onLabelChage }) => {
    const [showLabelDropdown, setShowLabelDropdown] = useState(false);

    useLayoutEffect(() => {
        function outsideClickHandler(ev) {
            const dropDownElmt = document.getElementById(`af-btn-change-${tempId}`);
            if(dropDownElmt){
                if(!dropDownElmt.contains(ev.target)){
                    setShowLabelDropdown(false);
                }
            }
        }
        document.body.addEventListener('click', outsideClickHandler);
    }, [tempId]);

    const handleOnLabelChange = (tempId, label) => {
        onLabelChage(tempId, label);
        setShowLabelDropdown(false);
        console.log('clicked');
    }

    return (
        <div className="af-change-wrapper" id={`af-btn-change-${tempId}`}>
            <div 
                className="af-btn af-btn-change" 
                onClick={() => setShowLabelDropdown(true)} 
                title="Change label"
            >
                <FontAwesomeIcon icon={faCaretDown} />
            </div>
            {
                showLabelDropdown && (
                    <div className="label-dd-list">
                        <div className='af-dd-title'>Change label</div>
                        {
                            availableFields.map( ({label, borderColor}, index) => {
                                return label !== 'QR Code' ? (
                                    <div key={`${index}-${tempId}`} className="label-dd-item-wrapper">
                                        <div className="label-dd-item" onClick={() => handleOnLabelChange(tempId, label)}>
                                            <span>{label}</span>
                                            <FontAwesomeIcon icon={faPlusCircle} color={borderColor} />
                                        </div>
                                    </div>
                                ) : null
                            })
                        }
                    </div>
                )
            }
        </div>
    );
}
 
export default ChangeLabelDropdown;