import React from "react";
import { Formik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";

const AutofillableFieldForm = ({
  initialValues = {},
  title,
  onOk,
  show,
  onHide,
}) => {
  return (
    <Modal show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: initialValues.name || "",
            submit: false,
          }}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (!values.name) {
                return setErrors({ name: "Field name is required!" });
              }
              onOk(values);
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form id="name-form" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Field name</Form.Label>
                <Form.Control
                  name="name"
                  label="Field name"
                  isInvalid={!!errors.name}
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" form="name-form">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AutofillableFieldForm;
