import React from "react";
import { Modal } from 'react-bootstrap';


function SimpleModal({ show, children, onClose }) {
    return (
        <Modal show={show} onHide={onClose} >
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default SimpleModal;