import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import HrLine from "./hrLine";
import availableFields from "../availableFields";
import './style/fieldTypes.css';

const FieldTypes = ({ onClick, isEditPurchasePage = false }) => {
    return (
        <>
            <div className="editor-padding pt-0">
                <div className="editor-label-txt mb-3">Fields Types</div>

                {availableFields.map((f, index) => (
                    (isEditPurchasePage && f.label === "QR Code") ? (
                        null
                    ) : (
                        <div key={index} className="ft-wrapper">
                            <div className="ft-elmt" onClick={() => onClick(f.label)}>
                                <span>{f.label}</span>
                                <FontAwesomeIcon size='lg' icon={faPlusCircle} color={f.borderColor} />
                            </div>
                        </div>
                    )
                ))}

            </div>

            <HrLine />
        </>
    );
}
 
export default FieldTypes;