import { fabric } from 'fabric';

const lines = {
    top: null,
    left: null,
    right: null,
    bottom: null
}


function objectMovingAutoAlign(obj, canvas) {
    if (localStorage.getItem('isAlignEnabled') === 'false') return;

    //Set up an object representing its current position
    let curPos = {
        top: parseInt(obj.get('top')),
        left: parseInt(obj.get('left')),
        right: parseInt(obj.get('left') + obj.get('width')),
        bottom: parseInt(obj.get('top') + obj.get('height'))
    };

    //Set up an object that will let us be able to keep track of newly created lines
    let matches = {
        top: false,
        left: false,
        right: false,
        bottom: false
    }

    //Get the objects from the canvas
    let objects = canvas.getObjects();

    //For each object
    for (let i in objects) {

        //If the object we are looing at is a line or the object being manipulated, skip it
        if (objects[i] === obj || objects[i].get('type') === 'line') { continue; }

        //Set up an object representing the position of the canvas object
        let objPos = {
            top: parseInt(objects[i].get('top')),
            left: parseInt(objects[i].get('left')),
            right: parseInt(objects[i].get('left') + obj.get('width')),
            bottom: parseInt(objects[i].get('top') + obj.get('height'))
        }

        //Look at all 4 sides of the object and see if the object being manipulated aligns with that side.

        //Top////////////////////////////////////
        if (inRange(objPos.top, curPos.top)) {
            //We match. If we don't already have aline on that side, add one.
            if (!lines.top) {
                drawLine('top', objPos.top, canvas);
                //Keep track of the fact we found a match so we don't remove the line prematurely.
                matches.top = true;
                //Snap the object to the line
                obj.set('top', objPos.top).setCoords();
            }
        }
        //Left////////////////////////////////////
        if (inRange(objPos.left, curPos.left)) {
            if (!lines.left) {
                drawLine('left', objPos.left, canvas);
                matches.left = true;
                obj.set('left', objPos.left).setCoords();
            }
        }
        // //Right////////////////////////////////////
        // if (inRange(objPos.right, curPos.right)) {
        //     if (!lines.right) {
        //         drawLine('right', objPos.right, canvas);
        //         matches.right = true;
        //         obj.set('left', objPos.right - objects[i].get('width')).setCoords();
        //     }
        // }
        // //Bottom////////////////////////////////////
        // if (inRange(objPos.bottom, curPos.bottom)) {
        //     if (!lines.bottom) {
        //         drawLine('bottom', objPos.bottom, canvas);
        //         matches.bottom = true;
        //         obj.set('top', objPos.bottom - objects[i].get('height')).setCoords();
        //     }
        // }

        //Look at the side we matched on. If we did not match, and we have a line, remove the line.
        for (let i in matches) {
            let m = matches[i];
            let line = lines[i];
            if (!m && line) {
                canvas.remove(line);
                lines[i] = null;
            }

        }

    }
    canvas.renderAll();
}



function drawLine(side, pos, canvas) {
    let ln = null;
    let stroke = 'rgb(178, 207, 255)';

    switch (side) {
        case 'top':
            ln = new fabric.Line([canvas.get('width'), 0, 0, 0], {
                left: 0,
                top: pos,
                stroke
            });
            lines.top = ln;
            break;
        case 'left':
            ln = new fabric.Line([0, canvas.get('height'), 0, 0], {
                left: pos,
                top: 0,
                stroke
            });
            lines.left = ln;
            break;
        // case 'right':
        //     ln = new fabric.Line([0, canvas.get('height'), 0, 0], {
        //         left: pos,
        //         top: 0,
        //         stroke
        //     });
        //     lines.right = ln;
        //     break;
        // case 'bottom':
        //     ln = new fabric.Line([canvas.get('width'), 0, 0, 0], {
        //         left: 0,
        //         top: pos,
        //         stroke
        //     });
        //     lines.bottom = ln;
        //     break;
    }
    canvas.add(ln).renderAll();
}



function inRange(val1, val2) {
    const alignTolerance = 2;

    if ((Math.max(val1, val2) - Math.min(val1, val2)) <= alignTolerance) { return true; }
    else { return false; }
}


function removeAlignLines(canvas) {
    const objs = canvas.getObjects();

    for (let obj of objs) {
        if (obj.type === 'line') {
            canvas.remove(obj);
        }
    }
}


export {
    objectMovingAutoAlign,
    removeAlignLines
}