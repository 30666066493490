import React, { useState } from 'react';
import { Alert, Button, Form, Card } from "react-bootstrap";
import Swal from 'sweetalert2';
// custom components
import FontInputItem from './fontInputItem';
import { SimplePageTitle } from '../../../components/titles/pageTitles';
// request
import filesRequests from '../../../request/files';
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const weightsWords = [
    { weightName: 'normal', realWeight: 'normal' },
    { weightName: 'regular', realWeight: 'normal' },
    { weightName: 'light', realWeight: '100' },
    { weightName: 'thin', realWeight: '100' },
    { weightName: 'bold', realWeight: 'bold' },
    { weightName: '100', realWeight: '100' },
    { weightName: '200', realWeight: '200' },
    { weightName: '300', realWeight: '300' },
    { weightName: '400', realWeight: '400' },
    { weightName: '500', realWeight: '500' },
    { weightName: '600', realWeight: '600' },
    { weightName: '700', realWeight: '700' },
    { weightName: '800', realWeight: '800' },
    { weightName: '900', realWeight: '900' },
];

function detectFontWeight(fontFileName) {
    const filename = fontFileName.toLowerCase();

    for(let { weightName, realWeight } of weightsWords){
        if(filename.includes(weightName)){
            return realWeight;
        }
    }
    // in case doesn't match any weight
    return 'normal';
}



const SaveFonts = () => {
    const [errors, setErrors] = useState([]);
    const [fontName, setFontName] = useState("");
    const [fontsFiles, setFontsFiles] = useState([]);
    const [fontsWeights, setFontsWeights] = useState([]);
    const [inputsQty, setInputsQty] = useState(1);

    const onChangeFile = (ev, index) => {
        const file = ev.target.files[0];

        if(file){
            let _files = [...fontsFiles];
            _files[index] = file;
            setFontsFiles(_files);
            console.log(file);

            const suggestedWeight = detectFontWeight(file.name);
            let _weights = [...fontsWeights];
            _weights[index] = suggestedWeight;
            setFontsWeights(_weights);
        }
    }

    const onChangeWeight = (ev, index) => {
        const weight = ev.target.value;

        let _weights = [...fontsWeights];
        _weights[index] = weight;
        setFontsWeights(_weights);
    }


    const handleSubmit = async ev => {
        ev.preventDefault();

        if(fontsFiles.length){
            // check font weights values
            const weightNames = weightsWords.map( w => w.weightName);
            for(let weight of fontsWeights){
                let _w = weight.toLowerCase();
                if(weightNames.indexOf(_w) === -1){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Wait a moment',
                        text: 'Please check the font weight names, valid weights are: ' + weightNames.join(', ')
                    })
                    return;
                }
            }

            Swal.showLoading();

            for (let i = 0; i < fontsFiles.length; i++){
                const fontFile = fontsFiles[i];
                const fontWeight = (fontsWeights[i] || 'normal').toLowerCase();

                if(fontFile){
                    await filesRequests.uploadFont({
                        fontName,
                        fontFile,
                        fontWeight
                    })
                }
            }

            await Swal.fire({
                icon: 'success',
                title: "Font saved, redirecting",
                timer: 2000
            });
            
            // force load page to load new fonts
            window.location.href = '/pages/fontsList';
        }
    }

    const addInput = () => {
        setInputsQty(inputsQty + 1);
    }

    return (
        <div>
			<SimplePageTitle>
				Upload font
			</SimplePageTitle>
			
			<Card>
				<Card.Body>
					<Form onSubmit={handleSubmit} id="categoryForm">

						{
							errors.map( (errMsg, idx) => (
								<Alert className="my-3" variant="danger" key={idx}>
									<div className="alert-message">{errMsg}</div>
								</Alert>
								)
							)
						}

                        <Form.Group className="mb-3">
                            <Form.Label>Font family name</Form.Label>
                            <Form.Control 
                                type="text"
                                onChange={ev => setFontName(ev.target.value)} 
                                value={fontName}
                                placeholder="e.g verdana"
                                required
                            />
                        </Form.Group>

                        <h3 className="h3">Upload font files</h3>

                        {
                            new Array(inputsQty).fill(0).map((_, index) => (
                                <FontInputItem
                                    key={index}
                                    onChangeFile={ev => onChangeFile(ev, index)}
                                    onChangeWeight={ev => onChangeWeight(ev, index)}
                                    weightValue={fontsWeights[index] || ''}
                                />
                            ))
                        }

                        <div className='mb-4'>
                            <Button onClick={addInput} type="button" size="sm" variant="success">
                                <span className='mx-2'>Add more</span>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </div>


						<Button type="submit" size="lg" variant="primary">Save font</Button>
					</Form>
				</Card.Body>
			</Card>
		</div>
    );
}
 
export default SaveFonts;