function JSON2FormData(json) {
    const formData = new FormData();

    for (let [key, value] of Object.entries(json)) {
        formData.append(key, value);
    }

    return formData;
}

export {
    JSON2FormData
}