import React, { useState, useEffect, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import { Select } from "antd";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { toast } from 'react-toastify';
// custom components
import { SimplePageTitle } from "../../../components/titles/pageTitles";
import SimpleButtonLink from '../../../components/Links/simpleButtonLink';
// import SelectProductCategory from "./selectProductCategory";
import ProductItem from './productItem';
// request
import productRequests from "../../../request/products";
import categoriesRequest from "../../../request/categories";
// hooks
import { useIsMounted } from "../../../hooks/useIsMounted";

const productsMap = new Map(); // for easy finding 
let requestTimeout, toastId;

function ProductsList() {
    const isMounted = useIsMounted();
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [mainCategories, setMainCategories] = useState([]);
    const [categorySelected, setCategorySelected] = useState('');
    const [isSavingOrder, setIsSavingOrder] = useState(false);


    const handleFilterByCategory = categoryId => {
        if (!categoryId || !products.length || isSavingOrder) return;

        const _products = products.map(p => ({
            ...p,
            categoriesIds: p.categoriesIds.map(obj => obj._id)
        }));
        const _filtered = _products.filter(p => p.categoriesIds.includes(categoryId));
        if (_filtered.length) {
            _filtered.sort((a, b) => a.productOrderIndex > b.productOrderIndex ? 1 : -1);
        }
        setFilteredProducts(_filtered);
        setCategorySelected(categoryId);
    }

    const handleResetCategoryFilter = () => {
        if (isSavingOrder) return;
        setFilteredProducts(products);
        setCategorySelected('');
    }

    useEffect(() => {
        productRequests.getAllProducts()
            .then(data => {
                if (isMounted.current) {
                    setFilteredProducts(data);
                    setProducts(data);
                    // save products on map
                    if (Array.isArray(data)) {
                        productsMap.clear();
                        data.forEach(p => { productsMap.set(p._id, p) });
                    }
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setLoading(false);
                }
            })

        categoriesRequest.getCategories()
            .then(_categories => {
                if (isMounted.current) {
                    const categories = _categories.map(c => ({ title: c.name, value: c._id, label: c.name }));
                    categories.push({ title: '', value: '', label: 'Filter by category' }); // default
                    setMainCategories(categories);
                }
            });
    }, []);

    const moveProductCard = useCallback((dragIndex, hoverIndex) => {
        if(!filteredProducts.length) return;

        if (!categorySelected){
            toast.warning('Please select a category before ordering products', { autoClose: 4000 });
            return;
        };

        setFilteredProducts((prevFilteredProducts) => {
            const itemsUpdated = update(prevFilteredProducts, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevFilteredProducts[dragIndex]],
                ],
            })
            // save new index on state
            setProducts(() => {
                const minIndex = Math.min(...itemsUpdated.map(p => p.productOrderIndex));
                for (let i = 0; i < itemsUpdated.length; i++) {
                    const item = itemsUpdated[i];
                    productsMap.set(item._id, {
                        ...item,
                        productOrderIndex: minIndex + i,
                        categoriesIds: productsMap.get(item._id).categoriesIds // overwrite value the way it was originally brought in.
                    });
                }
                return [...productsMap.values()];
            });

            return itemsUpdated;
        });

        setIsSavingOrder(true);
        // toastId = toast.loading('Saving product order...', { position: "bottom-center", autoClose: 1500 });

        // ensure update backend once state finish updating.
        clearTimeout(requestTimeout);
        requestTimeout = setTimeout(() => {
            console.log('inside timeout');
            setFilteredProducts(prevFilteredProducts => { // using setState callback to obtain current state after the update
                const updatedOrderIndexes = prevFilteredProducts.map(p => productsMap.get(p._id));
                productRequests.updateProductsOrder(updatedOrderIndexes)
                    .then(() => toast.success("Saved", { position: "bottom-center" }))
                    .catch(() => toast.error("Can't update product order", { position: "bottom-center" }))
                    .finally(() => { setIsSavingOrder(false); });

                return [...prevFilteredProducts];
            })
        }, 2000);
    }, [categorySelected]);


    return (
        <div>
            <div className="d-flex justify-content-between">
                <SimplePageTitle>Products</SimplePageTitle>

                <SimpleButtonLink
                    to="/pages/createProduct"
                    title="Add new product"
                />
            </div>

            <div className="productpage-filters">
                {
                    (mainCategories.length > 0) && (
                        <Select
                            options={mainCategories}
                            onChange={handleFilterByCategory}
                            placeholder="Filter by category"
                            allowClear
                            onClear={handleResetCategoryFilter}
                            loading={loading}
                            value={categorySelected}
                        />
                    )
                }

            </div>

            {
                loading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner size="lg" animation="border" />
                    </div>
                ) : (
                    <>
                        {
                            (filteredProducts.length > 0) ? (
                                <div id="products-grid-container">
                                    <DndProvider backend={HTML5Backend}>
                                        {
                                            filteredProducts.map((p, i) => (
                                                <ProductItem
                                                    {...p}
                                                    key={p._id}
                                                    index={i}
                                                    moveProductCard={moveProductCard}
                                                />
                                            ))
                                        }
                                    </DndProvider>
                                </div>
                            ) : (
                                <h4 className="mt-4">There are no products</h4>
                            )
                        }
                    </>
                )
            }
        </div>
    )

}

export default ProductsList;