import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
// requests
import autocompleteRequests from '../../../request/autocompleteValues';
// custom components
import { SimplePageTitle } from '../../../components/titles/pageTitles';


const NameAndAddressForm = ({ typeOfForm }) => {
    const [formState, setFormState] = useState({name: '', address: ''});
    const navigate = useNavigate();

    const handleSubmit = async ev => {
        ev.preventDefault();
        Swal.showLoading();

        try {
            // save hall or shul name first
            await autocompleteRequests.saveAutocompleteValue({
                autocompleteLabel: typeOfForm,
                value: formState.name 
            });
    
            // save shul or hall address
            if(formState.address){
                await autocompleteRequests.saveAutocompleteValue({
                    autocompleteLabel: typeOfForm + ' address',
                    value: formState.address,
                    referenceValue: formState.name
                })
            }

            await Swal.fire({
                title: typeOfForm + ' info saved!',
                text: `Redirecting to ${typeOfForm}s list`,
                icon: 'success',
                timer: 2000
            });
            navigate(`/pages/${typeOfForm}s`);
            
        } catch (err) {
            console.error(err.message);
            Swal.fire({
                title: 'Error saving ' + typeOfForm + ' info',
                icon: 'error'
            });
        }
    }

    const handleChange = ev => {
        const { name, value } = ev.target;

        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }


    return (
        <div>
            <SimplePageTitle>
                Add new <b>{typeOfForm}</b> to the list
            </SimplePageTitle>

            <Card>
				<Card.Body>
					<Form onSubmit={handleSubmit} id="productForm">
                        <Form.Group className="mb-3">
                            <Form.Label>{typeOfForm} name</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="name"
                                onChange={handleChange} 
                                autoComplete="off"
                                value={formState.name}
                                required
                            />
                        </Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>{typeOfForm} address</Form.Label>
							<Form.Control 
								type="text" 
								name="address" 
								defaultValue={formState.address} 
								onChange={handleChange}
                                autoComplete="off"
							/>
						</Form.Group>

						<Button type="submit" size="lg" variant="primary">Save {typeOfForm} info</Button>
					</Form>
				</Card.Body>
			</Card>
        </div>
    );
}
 
export default NameAndAddressForm;