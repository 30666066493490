import React from "react";
import { Formik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";

const TagModalForm = ({
  initialValues = {},
  title,
  onOk,
  show,
  onHide,
  tagCategories,
}) => {
  return (
    <Modal show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            tag: initialValues.tag || "",
            category: initialValues.category || "",
            submit: false,
          }}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (!values.tag) {
                return setErrors({ tag: "Tag is required!" });
              }
              onOk(values);
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form id="tag-form" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Tag Name</Form.Label>
                <Form.Control
                  name="tag"
                  label="Tag Name"
                  isInvalid={!!errors.tag}
                  value={values.tag}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.tag && (
                  <Form.Control.Feedback type="invalid">
                    {errors.tag}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {!!tagCategories.length && (
                <Form.Group className="mb-3">
                  <Form.Label>Tag Category</Form.Label>
                  <Form.Select
                    name="category"
                    label="Tag Category"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.category}
                  >
                    <option value="">Select a category</option>
                    {tagCategories.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" form="tag-form">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TagModalForm;
