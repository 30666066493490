import { createContext, useEffect, useState, useContext } from "react";
// request
import filesRequests from "../request/files";


const FontsContext = createContext(null);


function createFontsCssCode(fontsArr) {
    let cssCode = '';
    for(let font of fontsArr){
        cssCode += `
            @font-face {
                font-family: "${font.name}";
                src: url("${font.path}");
                font-weight: ${font.weight};
                font-style: ${ (/italic/i).test(font.path) ? "italic" : "normal" };
            }
        `;
    }
    return cssCode;
}


function injectFontsInHeader(fontsCssCode) {
    const style = document.createElement('style');
    style.textContent = fontsCssCode;
    document.head.appendChild(style);
}


function parseWeightsAndItalics(fontsArr) {
    const fontsAvailables = {};
    const isItalic = originalName => (/italic/i).test(originalName);

    for(let font of fontsArr){
        const { name, originalName, weight } = font;
        
        if(fontsAvailables[name]){
            // add weight
            if(!fontsAvailables[name].weights.includes(weight)){
                fontsAvailables[name].weights.push(weight)
            }
            // check if italic is available fot that weight
            if(isItalic(originalName)){
                fontsAvailables[name].italics.push(weight)
            }
        }else{
            fontsAvailables[name] = {
                weights: [weight],
                italics: isItalic(originalName) ? [weight] : []
            }
        }
    }

    return fontsAvailables;
}



function FontsProvider({ children }) {
  const [fonts, setFonts] = useState([]);

  useEffect(() => {
    filesRequests.getAllFonts()
        .then(_fonts => {
            const fontsCssCode = createFontsCssCode(_fonts);
            injectFontsInHeader(fontsCssCode); 
            setFonts(_fonts);
        });
  }, []);


  return (
    <FontsContext.Provider value={{ fonts }}>
        {children}
    </FontsContext.Provider>
  );
}

// custom hook
function useFonts() {
    const { fonts } = useContext(FontsContext);
    return {
        fonts: parseWeightsAndItalics(fonts)
    }
}

export { FontsContext, FontsProvider, useFonts };
