import React, { useState } from 'react';
import { Form, Card } from 'react-bootstrap';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
// custom components
import { SimplePageTitle } from '../../../../components/titles/pageTitles';
import PurchasedItem from './purchasesListItem';
// requests
import customerPurchasesRequests from '../../../../request/customerPurchases';
// utils
import parseEditLinkParams from './parseEditLinkParams';


const EditCustomerCardSearch = () => {
    const [inputValues, setInputValues] = useState({ email: '', link: '' });
    const [notFoundMessage, setNotFoundMessage] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleChange = (ev) => {
		const { name, value } = ev.target;
		setInputValues({
			...inputValues,
			[name]: value
		});
	}

    const handleSubmit = async ev => {
        ev.preventDefault();
        const { email, link } = inputValues;
        let _results;
        if(!email && !link) return;
        
        setLoading(true);

        if(email){
            _results = (await customerPurchasesRequests.getCustomerPurchasesByEmail({ customerEmail: email })) || [];
        }else if(link){
            const linkParams = parseEditLinkParams(link);
            const r = await customerPurchasesRequests.getWooOrderItem(linkParams);
            _results = r ? [r] : [];
        }

        console.log(_results);
        
        setInputValues({ email: '', link: '' });
        setResults(_results);
        setLoading(false);

        if(!_results?.length){
            let _notFoundMessage = email ? "No results for " + email : "No results associated with this link.";
            setNotFoundMessage(_notFoundMessage); 
        }else{
            setNotFoundMessage('');
        }
    }


    return (
        <div>
            <SimplePageTitle>Search customer orders to edit</SimplePageTitle>

            <Card>
				<Card.Body>
					<Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
							<Form.Label>By customer email</Form.Label>
							<Form.Control
								name="email"
                                value={inputValues.email}
								onChange={handleChange}
							/>
						</Form.Group>
                        <Form.Group className="mb-3">
							<Form.Label>By edit purchase link</Form.Label>
							<Form.Control
								name="link"
                                value={inputValues.link}
								onChange={handleChange}
							/>
						</Form.Group>

						<Button type="primary" onClick={handleSubmit} size="large" loading={loading}>Search</Button>
					</Form>
				</Card.Body>
			</Card>

            {
                notFoundMessage && (
                    <Card>
                        <Card.Body>
                            {notFoundMessage}
                        </Card.Body>
                    </Card>
                )
            }

            {
                results.map( (result, idx) => (
                    <PurchasedItem {...result} key={idx} />
                ))
            }
        </div>
    );
}
 
export default EditCustomerCardSearch;