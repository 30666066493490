import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2'
// requests
import autocompleteRequests from '../../../request/autocompleteValues';
// custom components
import { SimplePageTitle } from '../../../components/titles/pageTitles';


const EditShulOrHall = () => {
    const { state: routeState } = useLocation();
    const [formState, setFormState] = useState({ 
        name: routeState.item.value || '',  
        address: routeState.item.associatedValue?.value || '' 
    });
    const navigate = useNavigate();

    const handleChange = ev => {
        const { name, value } = ev.target;
        setFormState(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handleSubmit = async ev => {
        ev.preventDefault();
        Swal.showLoading();

        const { typeOfEdit } = routeState;
        const shulOrHall = routeState.item;
        const shulOrHallAddress = routeState.item.associatedValue;

        // first update shul or hall name
        await autocompleteRequests.updateAutocompleteValue({
            _id: shulOrHall._id,
            autocompleteLabel: shulOrHall.autocompleteLabel,
            value: formState.name,
            approved: true
        });
        // then update or create shul address
        if(!shulOrHallAddress && formState.address){
            // create
            await autocompleteRequests.saveAutocompleteValue({
                autocompleteLabel: `${typeOfEdit} address`,
                value: formState.address,
                referenceValue: formState.name
            });
        }
        else if(shulOrHallAddress !== formState.address){
            // update
            await autocompleteRequests.updateAutocompleteValue({
                ...shulOrHallAddress,
                value: formState.address,
                referenceValue: formState.name,
                approved: true
            });
        }

        await Swal.fire({
            title: typeOfEdit + ' updated!',
            text: `redirecting to ${typeOfEdit}s list`,
            icon: 'success',
            timer: 1500
        });
        navigate(`/pages/${typeOfEdit}s`);
    }

    return (
        <div>
            <SimplePageTitle>
                Edit {routeState.typeOfEdit}
            </SimplePageTitle>

            <Card>
				<Card.Body>
					<Form onSubmit={handleSubmit} id="productForm">
                        <Form.Group className="mb-3">
                            <Form.Label>{routeState.typeOfEdit} name</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="name"
                                onChange={handleChange} 
                                autoComplete="off"
                                value={formState.name}
                                required
                            />
                        </Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>{routeState.typeOfEdit} address</Form.Label>
							<Form.Control 
								type="text" 
								name="address" 
								defaultValue={formState.address} 
								onChange={handleChange}
                                autoComplete="off"
							/>
						</Form.Group>

						<Button type="submit" size="lg" variant="primary">Update {routeState.typeOfEdit} info</Button>
					</Form>
				</Card.Body>
			</Card>

        </div>
    );
}
 
export default EditShulOrHall;