// this file is a "temporary" file, if you want to fix a simple bug for all the products or make
// a change on many data you can write your scripts here

// once you complete the task you can delete or comment the code in this file


// import productRequests from '../request/products';
// import React from 'react';

const TempFile = () => {

    return null;

    // const handleUpdateWooIds = async () => {
    //     try {
    //         const products = await productRequests.getAllProducts();
    //         const productsToUpdate = products.filter(p => p.wooProductId === 0);

    //         for (let product of productsToUpdate) {
    //             const newWooId = await productRequests.wpUpsertProduct({
    //                 woo_product_id: 0,
    //                 customizer_product_id: product._id,
    //                 name: product.name,
    //                 price: product.price,
    //                 image_url: product.filesId.pathWithWatermark,
    //                 status: product.published ? 'publish' : 'draft'
    //             });

    //             const updated = await productRequests.updateProduct({
    //                 _id: product._id,
    //                 wooProductId: newWooId
    //             })

    //             console.log('updated', updated);
    //         }

    //         console.log('###ready');

    //     } catch (err) {
    //         console.log("can't update");
    //         console.error(err.message);
    //     }

    // }

    // return (
    //     <>
    //         <h1>update wooProductId</h1>
    //         <button onClick={handleUpdateWooIds}>update</button>
    //     </>
    // );
}

export default TempFile;
