import { useState, useEffect } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
// requests
import filesRequests from "../../../request/files";
import miscellaneousRequests from "../../../request/miscellaneous";
// custom components
import WatermarkItem from './watermarkItem';
import { SimplePageTitle } from '../../../components/titles/pageTitles';
import WatermarkModalForm from './watermarkModalForm';


const Watermarks = () => {
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [watermarks, setWatermarks] = useState([]);
    const [defaultWatermark, setDefaultWatermark] = useState(null);

    const closeModal = () => setShow(false);
    const showModal = () => setShow(true);

    const uploadWatermark = async (file) => {
        if(!file) return;

        try {
            setLoading(true);
            const uploaded = await filesRequests.saveWatermark(file);
            setWatermarks([...watermarks, uploaded]);
        } catch (err) {
            console.log(err.message);
        }
        finally{
            setLoading(false);
            setShow(false);
        }

    }

    const handleDelete = async (_id) => {
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        });
        if(!isConfirmed) return;

        try {
            setLoading(true);
            const deleted = await filesRequests.deleteWatermark(_id);
            console.log(deleted);
            // re-draw watermarks 
            const _watermarks = watermarks.filter( w => w._id !== _id);
            setWatermarks(_watermarks);
            
            toast.success("Deleted");
        } catch (err) {
            console.log(err.message);
        }
        finally { setLoading(false); }
    }

    useEffect(() => {
        filesRequests.watermarks()
            .then( _watermarks => setWatermarks(_watermarks))
            .catch( err => console.error(err.message))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        miscellaneousRequests.getDefaultWatermarkId()
            .then( _id => setDefaultWatermark(_id))
            .catch( err => console.error(err.message))
    }, []);
    
    return (
        <>
            <div className="d-flex justify-content-between">
                <SimplePageTitle>Watermarks</SimplePageTitle>
                <Button size='lg' color='primary' onClick={showModal}>Add new watermark</Button>
            </div>

            <WatermarkModalForm
                onHide={closeModal}
                onSubmit={uploadWatermark}
                show={show}
                loading={loading}
            />

            <div className="w-items-wrapper">
            { 
                loading ? (
                    <Spinner animation='border'/> 
                ) : (
                    watermarks.length > 0 ? (
                        watermarks.map( w => (
                            <WatermarkItem 
                                key={w._id} {...w} 
                                isDefault={w._id===defaultWatermark}  
                                setDefault={setDefaultWatermark}
                                onDelete={handleDelete}
                            />
                        ))
                    ) : (
                        <h4>There are no watermarks</h4>
                    )
                )
            }
            </div>
        </>
    );
}
 
export default Watermarks;