import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, ButtonGroup, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { fabric } from 'fabric';
import { useDrag, useDrop } from 'react-dnd';
// request
import productRequests from "../../../request/products";
import fieldsRequests from "../../../request/fields";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
// utils
import { wait } from "../../../utils/wait";
import {
    assingObjectsToCanvas,
    setCanvasAtResoution,
    setCustomFontsWhenLoaded
} from "../../../utils/canvas";

import './style/productList.css';

const CANVAS_SIZE = 150; // px
const DND_TYPE = 'product_card';

function ProductItem(props){
    const {
        _id,
        name,
        price,
        filesId,
        published,
        wooProductId,
        thumbnailWithPlaceholders,
        index,
        moveProductCard
    } = props;

    // +++++++++++++++++++++++++++++++++++++++ dnd start ++++++++++++++++++++++++++
    const ref = useRef(null)

    const [{ handlerId }, drop] = useDrop({
        accept: DND_TYPE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveProductCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: DND_TYPE,
        item: () => {
            return { ...props }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    drag(drop(ref))
    // +++++++++++++++++++++++++++++++++++++++ dnd end ++++++++++++++++++++++++++  

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleDelete = async () => {
        const { isConfirmed } = await Swal.fire({
            title: 'Do you want to delete ' + name + '?',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, delete',
        });

        if(isConfirmed){
            await wait(200);
            Swal.showLoading();
            // delete in mongodb
            let deletedMsg = await productRequests.deleteProduct({
                _id,
                path: filesId.path
            });
            // delete in wordpress
            await productRequests.wpDeleteProduct(wooProductId);

            if(deletedMsg){
                console.log(deletedMsg);
                await Swal.fire({
                    title: 'successfully deleted',
                    icon: 'success',
                    timer: 2000
                });
                if(pathname === '/pages/products'){
                    window.location.reload();
                }else{
                    navigate('/pages/products');
                }
            }
        }
    }

    // get product fields
    useEffect(() => {
        if (thumbnailWithPlaceholders) {
            return;
        }
        const _canvas = new fabric.Canvas("canvas" + _id, {
            selection: false
        });

        async function resizeAnsSetTextOnCanvas() {
            const _productFields = await fieldsRequests.getProductFields(_id);
            if(_productFields.length === 0) return false;

            const imageDimensions = {
                width: _productFields[0].originalImageWidth,
                height: _productFields[0].originalImageHeight
            }

            assingObjectsToCanvas(_canvas, _productFields);
            setCanvasAtResoution(
                _canvas,
                CANVAS_SIZE,
                _productFields[0].canvasWidth,
                imageDimensions
            );
            setCustomFontsWhenLoaded(_canvas);
            return true;
        }

        function setCanvasBackgroundImage() {
            _canvas.setDimensions({ width: CANVAS_SIZE, height: CANVAS_SIZE });

            fabric.Image.fromURL(filesId.pathWithWatermark, function (img) {
                // get image scale factor
                const scaleFactor = img.width / _canvas.width;
                const naturalCanvasHeight = img.height / scaleFactor;
                _canvas.setDimensions({ width: _canvas.width, height: naturalCanvasHeight });

                _canvas.setBackgroundImage(img, _canvas.renderAll.bind(_canvas), {
                    scaleX: _canvas.width / img.width,
                    scaleY: _canvas.height / img.height
                });
            }, {
                crossOrigin: 'anonymous'
            });
        }

        resizeAnsSetTextOnCanvas()
        .then(() => {
            setCanvasBackgroundImage();
        })
    }, [thumbnailWithPlaceholders]);


    // copy a product
    const handleCopyProduct = async () => {
        Swal.fire({
            title: 'cloning ' + name,
            text: 'Please wait a little while',
            icon: 'info'
        });

        // clone in mongodb
        const cloned = await productRequests.cloneProduct(_id);
        // clone in wordpress
        const newWooProductId = await productRequests.wpUpsertProduct({
            woo_product_id: 0,
            customizer_product_id: cloned._id,
            name: cloned.name,
            price: cloned.price,
            image_url: cloned.filesId.pathWithWatermark,
            status: 'draft'
        });
        // update product with the correct woo_product_id
        const productUpdated = await productRequests.updateProduct({
            _id: cloned._id,
            wooProductId: newWooProductId
        });
        console.log('product cloned and woocomerce id updated', productUpdated);

        await Swal.fire({
            title: 'Cloned',
            text: 'Reloading page',
            icon: 'success',
            timer: 2000
        });

        window.location.reload();
    }


    return (
        <div className="product-item-elmt" ref={ref} style={{ opacity }} data-handler-id={handlerId}>
            {
                published && (
                    <div className="product-item-tag-publised">
                        publised
                    </div>
                )
            }

            <Card>
                <Card.Body className="product-card-body">
                    <div className="product-image-container">
                        {thumbnailWithPlaceholders ? (
                            <img
                                src={thumbnailWithPlaceholders}
                                alt="thumbnail"
                                style={{ width: CANVAS_SIZE }}
                            />
                        ) : (
                            <canvas id={"canvas" + _id} />
                        )}
                    </div>

                    <div className="product-item-info">
                        <h4>{name}</h4>

                        <Button
                            style={{width: '100%'}}
                            variant="light"
                        >
                            ${price}
                        </Button>
                        <Button
                            onClick={() => navigate('/pages/editProductFields/'+ _id, {
                                state: props
                            })}
                            variant="primary"
                            style={{width: '100%'}}
                            className="mt-2"
                        >
                            Edit product fields
                        </Button>
                    </div>
                </Card.Body>
                <Card.Footer className="product-footer">
                    <ButtonGroup style={{width: '100%'}}>
                        <Button
                            onClick={handleCopyProduct}
                            variant="primary"
                            className={'mx-2'}
                            title="Copy product"
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                        <Button
                            onClick={() => navigate('/pages/editProduct/'+ _id)}
                            variant="warning"
                            className={'mx-2'}
                            title="Edit"
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button
                            onClick={handleDelete}
                            variant="danger"
                            className='mx-2'
                            title="Delete"
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card>
        </div>
    )

}

export default ProductItem;