import { useMemo } from "react";

/**
 * @template T
 * @param {Array<T>} collection
 * @param {keyof T} key
 * @returns
 */
export default function useGroupBy(
  collection,
  key
) {
  return useMemo(() => {
    /** @type {Record<string, Array<T>>} */
    const map = {};
    collection.forEach((item) => {
      if (!map[item[key]]) {
        map[item[key]] = []
      }
      map[item[key]].push(item);
    });
    return map;
  }, [collection]);
}
