// some utils for fabricjs
import { fabric } from 'fabric';
import FontFaceObserver from 'fontfaceobserver';


function setCanvasAtResoution(canvas, newWidth, originalEditorWidth, imageDimensions) {
    let scaleMultiplier = newWidth / originalEditorWidth;

    let objects = canvas.getObjects();
    for (let obj of objects) {
        obj.scaleX *= scaleMultiplier;
        obj.scaleY *= scaleMultiplier;
        obj.left *= scaleMultiplier;
        obj.top *= scaleMultiplier;
        obj.selectable = false;
        obj.hoverCursor = 'default';
        obj.setCoords();
    }

    const scaleFactorImg = imageDimensions.width / newWidth;
    const newCanvasHeight = imageDimensions.height / scaleFactorImg;

    let imageObj = canvas.backgroundImage;
    if (imageObj) {
        imageObj.scaleX = newWidth / imageDimensions.width;
        imageObj.scaleY = newCanvasHeight / imageDimensions.height;
    }


    // canvas.discardActiveObject();
    canvas.setWidth(newWidth);
    canvas.setHeight(newCanvasHeight);
    canvas.renderAll();
    canvas.calcOffset();
}


function assingObjectsToCanvas(canvas, fieldsObjects = []) {
    if (fieldsObjects.length === 0) return false;

    fabric.util.enlivenObjects(fieldsObjects, _objects => {
        for (let obj of _objects) {
            let objToAdd;

            if (obj.type === 'rect') {
                objToAdd = new fabric.Rect({
                    ...obj,
                    caching: false,
                    objectCaching: false
                })
            }
            else {
                objToAdd = new fabric.Textbox(obj.text, {
                    ...obj,
                    caching: false,
                    objectCaching: false
                })
            }
            // obj.caching = false;
            canvas.add(objToAdd);
        }
    });

    return true;
}



function setCustomFontsWhenLoaded(populatedCanvas) {
    const defaultFonts = ['arial', 'verdana', 'consolas', 'fantasy'];

    for (let textObj of populatedCanvas.getObjects()) {
        const { fontFamily, fontWeight, fontSize, type, width, maxWidth } = textObj;

        if (type === 'rect') continue;

        if (!defaultFonts.includes(fontFamily.toLowerCase())) {
            const fontObserver = new FontFaceObserver(fontFamily, {
                weight: fontWeight,
            });

            fontObserver.load(null, 10000)
                .then(() => {
                    textObj.fontFamily = fontFamily;
                    // for some reason the fontFamily doesn't update when you change it.
                    // but if we change the font size it updates!
                    // idk why this happens, anyway we can change the original font size, then
                    // render, change it again and render again
                    // it's a weird solution but works
                    textObj.fontSize = fontSize + 1;
                    populatedCanvas.renderAll();
                    textObj.fontSize = fontSize;
                    window.fabric.util.clearFabricFontCache(fontFamily);

                    // in some cases with some font sizes the fabricjs TextBox doesn't load proper width
                    // for some reason it renders a bigger width, for example instead 250px width it renders 274px
                    // so with this function we force to render the original width using maxWidth property
                    // (maxWidth property was previously added when you make a save the first time and the following times)
                    if (maxWidth && (maxWidth !== width)) {
                        textObj.width = maxWidth;
                    }
                    populatedCanvas.renderAll();
                })
                .catch(err => {
                    console.error("Can't load the " + fontFamily + " font " + err.message);
                })
        }
    }
}


export {
    setCanvasAtResoution,
    setCustomFontsWhenLoaded,
    assingObjectsToCanvas
}