import React, { createContext, useState } from "react";

const ModalWithStepsContext = createContext(null);

function ModalWithStepsProvider({ children }) {
    const [modalShow, setModalShow] = useState(false);
    const [modalCurrentStep, setModalCurrentStep] = useState('');

    return (
        <ModalWithStepsContext.Provider
            value={{
                modalShow,
                setModalShow,
                modalCurrentStep,
                setModalCurrentStep
            }}
        >
            {children}
        </ModalWithStepsContext.Provider>
    );
}

export { ModalWithStepsProvider, ModalWithStepsContext };
