import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Spinner, Table, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
// custom components
import { SimplePageTitle } from '../../../components/titles/pageTitles';
import SimpleButtonLink from '../../../components/Links/simpleButtonLink';
// request
import filesRequests from '../../../request/files';
// hooks
import { useIsMounted } from '../../../hooks/useIsMounted';

const isItalic = path => (/italic/i).test(path);


const FontsList = () => {
    const [loading, setLoading] = useState(true);
    const [fonts, setFonts] = useState([]);
    const isMounted = useIsMounted();

    const handleDelete = async (fontId, name) => {
        const { isConfirmed } = await Swal.fire({
            title: `Delete font ${name}?`, 
            confirmButtonText: "Yes delete it",
            icon: "warning",
            showLoaderOnConfirm: true
        });

        if(isConfirmed){
            await filesRequests.deleteFont(fontId);
            await Swal.fire({
                title: "Font deleted",
                timer: 1500,
                icon: "success"
            });
            window.location.reload(); 
        }
    }

    useEffect(() => {
        filesRequests.getAllFonts()
            .then( _fonts => {
                console.log(_fonts);
                if(isMounted.current){
                    _fonts.sort( (f1, f2) => f1.name < f2.name ? -1 : 1 );
                    setFonts(_fonts);
                }
            })
            .finally(() => {
                if(isMounted.current) setLoading(false);
            })
    }, []);

    return (
        <>
        <Helmet title="Fonts" />
        <div className="d-flex justify-content-between">
            <SimplePageTitle>Fonts List</SimplePageTitle>

            <SimpleButtonLink 
                to="/pages/saveFonts"
                title="Add new font"
            />
        </div>
        
        {
            loading ? (
                <Spinner animation='border' />
            ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Font Family name</th>
                            <th>Weight</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fonts.length ? (
                                fonts.map( (f, idx) => (
                                    <tr key={idx}>
                                        <td>{idx + 1}</td>
                                        <td style={{
                                                fontFamily: f.name,
                                                fontWeight: f.weight,
                                                fontSize: 25,
                                                fontStyle: isItalic(f.path) ? 'italic' : 'normal',
                                                color: 'black'
                                            }}>
                                                {f.name}
                                        </td>
                                        <td>{f.weight + (isItalic(f.path) ? ' italic' : '')}</td>
                                        <td>
                                            <Button 
                                                onClick={() => handleDelete(f._id, f.name)}
                                                variant="danger"
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={4} className="text-center">There are no fonts</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            )
        }
        </>
    );
}

 
export default FontsList;