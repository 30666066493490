import React from 'react';
import { Form, Row, Col } from "react-bootstrap";

const FontInputItem = ({ onChangeFile, onChangeWeight, weightValue }) => {
    return (
        <Row>
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label>File</Form.Label>
                    <Form.Control 
                        type="file" 
                        name="fontFile" 
                        placeholder="font file e.g .ttf .woff" 
                        onChange={onChangeFile}
                        accept=".otf,.ttf,.woff,.woff2"
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label>Weight</Form.Label>
                    <Form.Control
                        type='text'
                        onChange={onChangeWeight}
                        value={weightValue}
                        placeholder='normal by default'
                    />
                </Form.Group>
            </Col>
        </Row>
    );
}
 
export default FontInputItem;