import React, { useEffect, useMemo, useState } from "react";
import tagsRequests from "../request/tags";
import tagCategoriesRequests from "../request/tagCategories";
import useGroupBy from "../hooks/useGroupBy";

/**
 * @typedef {{ id: string; text: string; category: string }} Tag
 * @typedef {{ _id: string; name: string }} TagCategory
 */

const initialState = {
  /** @type {Array<Tag>} */
  tags: [],
  /** @type {Array<TagCategory & { tags: Array<Tag> }>} */
  tagsByCategories: [],
  /** @param {string} tag */
  createIfNotExists: (tag) => {}
};

const TagsContext = React.createContext(initialState);

function TagsProvider({ children }) {
  /** @type {[Array<Tag>, Dispatch<SetStateAction<Array<Tag>>>]} */
  const [tags, setTags] = useState([]);
  const [tagCategories, setTagCategories] = useState([]);
  const tagsGrouppedByCategory = useGroupBy(tags, "category")
  const tagsByCategories = useMemo(
    () =>
      tagCategories.map((category) => ({
        ...category,
        tags: tagsGrouppedByCategory[category._id] || [],
      })),
    [tagsGrouppedByCategory, tagCategories]
  );

  useEffect(() => {
    tagsRequests.getTags().then(data => {
      setTags(
        data.map((item) => ({
          id: item._id,
          text: item.tag,
          category: item.category,
        }))
      );
    });
    tagCategoriesRequests.getTagCategories().then(setTagCategories)
  }, [])

  const createIfNotExists = (tag) => {
    if (!tags.find(item => item.tag === tag)) {
      tagsRequests.createTag(tag).then(newTag => {
        setTags([...tags, { id: newTag._id, text: newTag.tag }]);
      });
    }
  }

  return (
    <TagsContext.Provider
      value={{
        tags,
        tagsByCategories,
        createIfNotExists,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
}

export { TagsProvider, TagsContext };
