import ajax from './ajax';
import { API_BASE_URL } from '../config';

const autocompleteRequests = {};

autocompleteRequests.saveAutocompleteValue = async function ({ autocompleteLabel, value, referenceValue = '' }) {
    const url = `${API_BASE_URL}/fields/SaveAutocompleteValue`;

    const valueSaved = await ajax.post(
        url,
        {
            autocompleteLabel,
            value,
            referenceValue,
            approved: true,
            createdByAdmin: true
        });
    return valueSaved;
}


autocompleteRequests.getAllAutocompleteValues = async function (autocompleteLabel = '') {
    const url = `${API_BASE_URL}/fields/AllAutocompleteValues/${autocompleteLabel}`;

    const values = await ajax.get(url, { token: true });
    return values;
}


autocompleteRequests.updateAutocompleteValue = async function (body) {
    const url = `${API_BASE_URL}/fields/UpdateAutocompleteValue`;

    const updated = await ajax.post(url, body, { token: true });
    return updated;
}


autocompleteRequests.deleteAutocompleteValue = async function ({ _id }) {
    const url = `${API_BASE_URL}/fields/DeleteAutocompleteValue`;

    console.log("the ID in request function", _id);

    const deleted = await ajax.post(url, { _id }, { token: true });
    return deleted;
}


export default autocompleteRequests;
