import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const SimpleButtonLink = ({to, title, className = null}) => {
    return (
        <Link to={to}>
            <Button className={className || ''} size="lg" variant="primary">
                {title}
            </Button>
        </Link>
    );
}

export default SimpleButtonLink;