import React, { useState, useEffect } from 'react';
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HrLine from './hrLine';
import { SketchPicker } from 'react-color';
import rgbHex from 'rgb-hex';
import './style/color.css';

const blockStyle = {
    default: {
        head: {
            height: '50px',
        },
        body: {
            borderWidth: '1px',
            borderColor: '#000',
            borderStyle: 'solid',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px'
        }
    }
};


const ChangeColor = ({ onChange, activeObject, productFields }) => {
    const [colors, setColors] = useState([]);

    const addColor = color => {
        if(!colors.includes(color)){
            setColors( c => [...c, color]);
        }
    }

    const handleChange = color => {
        const { r, g, b, a } = color.rgb;
        const hex = '#' + rgbHex(r,g,b,a);
        console.log(hex, activeObject.fill);
        if(hex.toLowerCase() === activeObject.fill) return;
        if(hex === '#000000ff' && activeObject.fill === '#000000') return;

        addColor(hex);
        onChange({ target: { name: 'fill', value: hex }})
    }

    const handleInputChange = ev => {
        let color = ev.target.value;
        if(color.length === 7) color += 'ff';

        addColor(color);
        onChange({target: { name: 'fill', value: color }})
    }

    const removeAlpha = colorHex => {
        return colorHex.length === 7 ? colorHex : colorHex.substring(0, 7);
    }

    // get text colors in the product
    useEffect(() => {
        let _colors = new Set();
        _colors.add('#000000ff');

        for(let obj of (productFields || []) ){
            let colorHex = obj.fill;
            if(colorHex.length === 7) colorHex += 'ff';
            _colors.add(colorHex);
        }
        _colors = Array.from(_colors);
        setColors(_colors);

    }, [productFields]);

    return (
        <>
            <div className="editor-padding">
                <div className="editor-label-txt">Color</div>

                <div>
                    <SketchPicker 
                        color={activeObject.fill || '#000000FF'}
                        presetColors={colors}
                        styles={blockStyle}
                        onChangeComplete={handleChange}
                    />
                    <div className='d-flex mt-2 align-items-center'>
                        <FontAwesomeIcon size='lg' className='mx-2' icon={faEyeDropper} />
                        <input 
                            type="color" 
                            onChange={handleInputChange} 
                            value={removeAlpha(activeObject.fill || '#000')} 
                            name='fill'
                            style={{cursor: 'pointer', width: '80%'}}
                        />
                    </div>
                </div>


            </div>

            <HrLine/>
        </>
    );
}
 
export default ChangeColor;