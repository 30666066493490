const availableFields = [
    {
        label: 'simple text',
        borderColor: '#519451'
    },
    {
        label: 'person name',
        borderColor: '#dd1b1b'
    },
    {
        label: 'hall',
        borderColor: '#e9ce37'
    },
    {
        label: 'hall address',
        borderColor: '#8d498a'
    },
    {
        label: 'shul',
        borderColor: '#2ca0ff'
    },
    {
        label: 'shul address',
        borderColor: '#ff4500'
    },
    {
        label: 'date',
        borderColor: '#ff04dd'
    },
    {
        label: 'parsha',
        borderColor: '#00ff37'
    },
    {
        label: 'parsha with weekday',
        borderColor: '#00fffa'
    },
    {
        label: 'QR Code',
        borderColor: '#5e5e5e'
    },
];

export default availableFields;