import ajax from "./ajax";
import { API_BASE_URL } from "../config";

const tagsRequests = {
  getTags: () => ajax.get(`${API_BASE_URL}/tags`, { token: true }),
  createTag: (data) => ajax.post(`${API_BASE_URL}/tags`, data, { token: true }),
  updateTag: (id, data) =>
    ajax.put(`${API_BASE_URL}/tags/${id}`, data, { token: true }),
  deleteTag: (id) => ajax.delete(`${API_BASE_URL}/tags/${id}`, { token: true }),
};

export default tagsRequests;
