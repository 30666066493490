import { useMemo } from "react";

/**
 * @template T
 * @param {Array<T>} collection
 * @param {keyof T} key
 * @returns
 */
export default function useKeyBy(
  collection,
  key
) {
  return useMemo(() => {
    /** @type {Record<string, T>} */
    const map = {};
    collection.forEach((item) => {
      map[item[key]] = item;
    });
    return map;
  }, [collection]);
}
