import ajax from './ajax';
import { API_BASE_URL } from '../config';

const authRequests = {};

authRequests.login = async function (email, password) {
    try {
        const url = `${API_BASE_URL}/auth/Login`;

        const sessionData = await ajax.post(url, {
            email, password
        });
        return sessionData;
    } catch (err) {
        console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');
        throw new Error('invalid credentials');
    }
}


authRequests.verifyToken = async function () {
    const url = `${API_BASE_URL}/auth/VerifyToken`;

    const isValid = await ajax.get(url, { token: true });
    return isValid;
}


export default authRequests;
