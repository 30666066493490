import React from 'react';
import { ModalWithStepsProvider } from '../../../contexts/ModalWithStepsContext';
import EditProductForm from './editProductForm';

const EditProduct = () => {
    return (
        <ModalWithStepsProvider>
            <EditProductForm />
        </ModalWithStepsProvider>
    )
}
 
export default EditProduct;