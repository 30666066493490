import ajax from './ajax';
import { API_BASE_URL, WP_API_BASE_URL } from '../config';
// utils
import { JSON2FormData } from '../utils/formData';

const productRequests = {};

productRequests.createProduct = async function (body) {
    const url = `${API_BASE_URL}/products/CreateProduct`;

    const productCreated = await ajax.post(url, body, { token: true });
    return productCreated;
}


productRequests.cloneProduct = async function (productId) {
    const url = `${API_BASE_URL}/products/CloneProduct`;

    const cloned = await ajax.post(url, { _id: productId }, { token: true });
    return cloned;
}


productRequests.getAllProducts = async function () {
    const url = `${API_BASE_URL}/products/GetAllProducts`;

    const products = await ajax.get(url, { token: true });
    return products;
}


productRequests.getProduct = async function (productId) {
    const url = `${API_BASE_URL}/products/GetProduct/${productId}`;

    const product = await ajax.get(url);
    return product;
}


productRequests.getProductAsAdmin = async function (productId) {
    const url = `${API_BASE_URL}/products/GetProductAsAdmin/${productId}`;

    const product = await ajax.get(url, { token: true });
    return product;
}


productRequests.updateProduct = async function (body) {
    const url = `${API_BASE_URL}/products/UpdateProduct`;

    const updated = await ajax.post(url, body, { token: true });
    return updated;
}


productRequests.updateProductsOrder = async function (productsArray) {
    const url = `${API_BASE_URL}/products/UpdateProductsOrder`;

    const updated = await ajax.post(url, { productsArray }, { token: true });
    return updated;
}


productRequests.deleteProduct = async function (body) {
    const url = `${API_BASE_URL}/products/DeleteProduct`;

    const deletedMsg = await ajax.post(url, body, { token: true });
    return deletedMsg;
}

// WordPress requests

productRequests.wpUpsertProduct = async function (body) {
    const formData = JSON2FormData(body);
    try {
        const response = await fetch(`${WP_API_BASE_URL}/product_save.php`, {
            method: 'POST',
            body: formData
        });
        const json = await response.json();
        console.log('wp json', json);
        return json.data.woo_product_id;

    } catch (err) {
        console.log('Error saving product in wordpress');
        return 0
    }
}


productRequests.wpDeleteProduct = async function (wooProductId) {
    const formData = JSON2FormData({
        woo_product_id: wooProductId
    });

    try {
        const response = await fetch(`${WP_API_BASE_URL}/product_delete.php`, {
            method: 'POST',
            body: formData
        });
        const json = await response.json();
        return json.success;

    } catch (err) {
        console.log('Error deleting product in wordpress');
        return false
    }
}



// requests with timeout
// let orderTimeoutId;

// productRequests.updateProductsOrder = function (productsArray) {
//     clearTimeout(orderTimeoutId);

//     return new Promise((resolve, reject) => {
//         try {

//             orderTimeoutId = setTimeout(async () => {
//                 const url = `${API_BASE_URL}/products/UpdateProductsOrder`;
//                 const updated = await ajax.post(url, { productsArray }, { token: true });
//                 resolve(updated);
//             }, 3000);

//         } catch (err) {
//             reject(err);
//         }
//     });
// }


export default productRequests;