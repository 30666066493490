import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import HrLine from "./hrLine";
import autofillableFieldsRequests from "../../../../request/autofillableFields";
import { showWarningToast } from "../../../../utils/warning";
import "./style/boxAlignment.css";

/** @param {{ canvas: fabric.Canvas; activeObjectStyles: fabric.Object; setActiveCanvasObjectStyles: (value: any) => void }} props */
const AutofillableField = ({ canvas, activeObjectStyles, setActiveCanvasObjectStyles }) => {
  const [fieldOptions, setFieldOptions] = useState([]);

  useEffect(() => {
    autofillableFieldsRequests.getAutofillableFields().then(setFieldOptions);
  }, []);

  const handleChange = (event) => {
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      showWarningToast();
      return;
    }

    const { value } = event.target;

    activeObject.autofillableField = value;
    setActiveCanvasObjectStyles({ ...activeObjectStyles, autofillableField: value });
  };

  return (
    <>
      <div className="editor-padding">
        <div className="editor-label-txt">Autofillable Field</div>

        <div className="ba-container">
          <Form.Group className="mt-2">
            <Form.Select
              name="fontFamily"
              onChange={handleChange}
              value={activeObjectStyles?.autofillableField || ""}
              disabled={!activeObjectStyles}
            >
              <option value="">- NONE -</option>
              {fieldOptions.map((field, idx) => (
                <option key={idx} value={field.name}>
                  {field.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
      </div>

      <HrLine />
    </>
  );
};

export default AutofillableField;
