import ajax from "./ajax";
import { API_BASE_URL } from "../config";

const autofillableFieldsRequests = {
  getAutofillableFields: () =>
    ajax.get(`${API_BASE_URL}/autofillable-fields`, { token: true }),
  getAutofillableFieldById: (id) =>
    ajax.get(`${API_BASE_URL}/autofillable-fields/${id}`, { token: true }),
  createAutofillableField: (data) =>
    ajax.post(`${API_BASE_URL}/autofillable-fields`, data, { token: true }),
  updateAutofillableField: (id, data) =>
    ajax.put(`${API_BASE_URL}/autofillable-fields/${id}`, data, { token: true }),
  deleteAutofillableField: (id) =>
    ajax.delete(`${API_BASE_URL}/autofillable-fields/${id}`, { token: true }),
};

export default autofillableFieldsRequests;
