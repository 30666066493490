import download from "downloadjs";

async function downloadVideo(videoUrl, filename) {
    try {
        const response = await fetch(videoUrl);
        const blob = await response.blob();
        download(blob, filename, 'video/mp4');

        return true;
    } catch (error) {
        console.log('Cannot download video ', error);
        return false;
    }
}


async function downloadImage(imageBase64URL, filename, mimeType) {
    try {
        download(imageBase64URL, filename, 'image/' + mimeType);
        return true;
    } catch (error) {
        console.log('Cannot download image ', error);
        return false;
    }
}

export {
    downloadVideo, 
    downloadImage
};