import ajax from "./ajax";
import { API_BASE_URL } from "../config";

const tagCategoriesRequests = {
  getTagCategories: () =>
    ajax.get(`${API_BASE_URL}/tag-categories`, { token: true }),
  createTagCategory: (data) =>
    ajax.post(`${API_BASE_URL}/tag-categories`, data, { token: true }),
  updateTagCategory: (id, data) =>
    ajax.put(`${API_BASE_URL}/tag-categories/${id}`, data, { token: true }),
  deleteTagCategory: (id) =>
    ajax.delete(`${API_BASE_URL}/tag-categories/${id}`, { token: true }),
};

export default tagCategoriesRequests;
