import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
// custom component
import CategoryButtonGroup from './ButtonGroup';
import FullWidthRow from '../../../components/tables/fullWitdhRow';
// style
import './styles/categoryListItem.css';

const DND_TYPE = 'category_item';


function CategoryListItem({ categoryName, categoryId, handleMoveItemDragged, index }) {
    const ref = useRef(null)

    const [{ handlerId }, drop] = useDrop({
        accept: DND_TYPE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            handleMoveItemDragged(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: DND_TYPE,
        item: () => {
            return { ...{ categoryName, categoryId, handleMoveItemDragged, index } }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0.4 : 1

    drag(drop(ref))


    return (
        <div ref={ref} style={{ opacity }} data-handler-id={handlerId} className="category-list-item"> 
            <div className="category-list-item-name">
                {categoryName}
            </div>
            <CategoryButtonGroup 
                categoryName={categoryName} 
                categoryId={categoryId}
            />
        </div>
    )
}


export default CategoryListItem;