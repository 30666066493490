import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import { ToastContainer } from 'react-toastify';

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { FontsProvider } from "./contexts/FontsContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";

import { AuthProvider } from "./contexts/JWTContext";
import { TagsProvider } from "./contexts/TagsContext";
// some global styles
import 'react-toastify/dist/ReactToastify.css';
import './global.css';

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Hazmoona Admin"
        defaultTitle="Hazmoona Admin"
      />
      <Provider store={store}>
        <ThemeProvider>
          <FontsProvider>
            <SidebarProvider>
              <LayoutProvider>
                <ChartJsDefaults />
                <TagsProvider>
                  <AuthProvider>
                    <ToastContainer autoClose={3500} limit={1} />
                    {content}
                  </AuthProvider>
                </TagsProvider>
              </LayoutProvider>
            </SidebarProvider>
          </FontsProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
