import React from "react";
import { Button } from "react-bootstrap";
import { LogOut } from "react-feather";
// hooks
import useAuth from '../../hooks/useAuth';


const SidebarFooter = () => {
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
  }

  return (
    <div className="d-flex justify-content-center" style={{ padding: '0 24px' }}>
      <Button
        onClick={handleSignOut}
        className="w-100"
        style={{
          backgroundColor: '#af52de',
          borderColor: '#2c0732'
        }}
      >
        Logout
        <LogOut size={18} className="align-middle" style={{ marginLeft: '12px' }} />
      </Button>
    </div>
  );
};

export default SidebarFooter;
