import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
import AuthLayout from '../../layouts/Auth';


const SignInPage = () => (
  <AuthLayout>
    <Helmet title="Sign In" />

    <div className="text-center mt-4 mb-4">
      <h2>Login to Hazmoona admin panel</h2>
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <SignIn />
        </div>
      </Card.Body>
    </Card>
  </AuthLayout>
);

export default SignInPage;
