import { Button } from 'react-bootstrap';


const btnStyle = {
    outline: 'none', width: '100%', border: 'none'
}

function parseLatestDate(productUpdatedDate, productFieldObj) {
    const dateOpts = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const parseDateStr = str => new Date(str).toLocaleDateString('en-US', dateOpts);

    if(!productFieldObj?.createdAt){
        return parseDateStr(productUpdatedDate);
    }
    // return newest date
    if(new Date(productUpdatedDate) > new Date(productFieldObj.createdAt)){
        return parseDateStr(productUpdatedDate);
    }else{
        return parseDateStr(productFieldObj.createdAt);
    } 
}


const SaveAndPublishButtons = ({ handleSaveDraft, handlePublishOrHide, productData, productFields }) => {
    const { updatedAt, published } = productData;

    return (
        <>
            <div className="editor-padding">
                
                    <Button 
                        size="lg" 
                        style={{ ...btnStyle, color: 'black', border: '1px solid black', backgroundColor: 'white' }}
                        onClick={handleSaveDraft}
                    >
                        {published ? 'Save Changes' : 'Save Draft'}
                    </Button>
                    <Button 
                        size="lg" 
                        style={{ ...btnStyle, backgroundColor: published ? '#ffbf00' : '#ae51dd', color: published ? 'black' : 'white' }}
                        className='mt-3'
                        onClick={handlePublishOrHide}
                    >
                        {published ? 'Hide' : 'Publish'}
                    </Button>
                    
                    <div className="post-info-text">
                        Last update: {parseLatestDate(updatedAt, productFields[0])}
                    </div>
            </div>

        </>
    );
}
 
export default SaveAndPublishButtons;