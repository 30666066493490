// icons
import TopV from '../../../../assets/img/icons/top-v.png';
import BottomV from '../../../../assets/img/icons/bottom-v.png';
import MiddleV from '../../../../assets/img/icons/middle-v.png';
import LeftH from '../../../../assets/img/icons/left-h.png';
import RighttH from '../../../../assets/img/icons/right-h.png';
import MiddleH from '../../../../assets/img/icons/middle-h.png';
// custom components
import HrLine from './hrLine';
// utils
import { showWarningToast } from '../../../../utils/warning';
// style
import './style/boxAlignment.css';


const BoxAlignment = ({ canvas, setActiveCanvasObjectStyles, handleAutoAlign }) => {

    const handleAlign = align => {
        const activeObj = canvas.getActiveObject();

        if(!activeObj){
            showWarningToast();
            return;
        }

        let objWidth, objHeight;
        
        if(activeObj.type === 'rect'){
            objWidth = activeObj.getScaledWidth();
            objHeight = activeObj.getScaledHeight();
        }
        else{
            objWidth = activeObj.width;
            objHeight = activeObj.height;
        }

        switch (align) {
            case 'left':    activeObj.left = 0; break;
            case 'top':     activeObj.top  = 0; break;
            case 'right':   activeObj.left = canvas.getWidth() - objWidth; break;
            case 'bottom':  activeObj.top  = canvas.getHeight() - objHeight; break;
            case 'centerV': activeObj.centerV(); break;
            case 'centerH': activeObj.centerH(); break;
            default: break;
        }

        canvas.renderAll();

        canvas.fire('alignment:change'); // useful for undo/redo

        setActiveCanvasObjectStyles( previousState => ({
            ...previousState,
            ...activeObj
        }));
    }

    return (
        <>
            <div className="editor-padding">
                <div className="editor-label-txt">Box Align</div>
                
                <div className='ba-container'>
                    <div className="ba-icons">
                        <div onClick={() => handleAlign('left')} className="ba-icon">
                            <img src={LeftH} alt="left align" />
                        </div>
                        <div onClick={() => handleAlign('centerH')} className="ba-icon">
                            <img src={MiddleH} alt="middle h align" />
                        </div>
                        <div onClick={() => handleAlign('right')} className="ba-icon">
                            <img src={RighttH} alt="right align" />
                        </div>
                    </div>

                    <div className="ba-divider" />

                    <div className="ba-icons">
                        <div onClick={() => handleAlign('top')} className="ba-icon">
                            <img src={TopV} alt="left align" />
                        </div>
                        <div onClick={() => handleAlign('centerV')} className="ba-icon">
                            <img src={MiddleV} alt="middle v align" />
                        </div>
                        <div onClick={() => handleAlign('bottom')} className="ba-icon">
                            <img src={BottomV} alt="bottom align" />
                        </div>
                    </div>
                </div>

                <div className="ba-autoalign">
                    <input 
                        type="checkbox" 
                        onChange={handleAutoAlign} 
                        id='autoalign'
                    />
                    <label htmlFor='autoalign'>Enable align help</label>
                </div>
            </div>

            <HrLine />
        </>
    );
}
 
export default BoxAlignment;