import { fabric } from "fabric";
import BGImage from '../assets/img/png-qr-big.png';
// import BGImageMini from '../assets/img/bg-mini-crop.png';
// request
import productUtilsRequests from "../request/productsUtils";


const fabricDefaultsOpts = {
    hasControls: false,
    lockScalingY: true,
}

/**
 * 
 * @param {number} currentLeft 
 * @param {number} qrSize 
 * @param {number} actualPosterWidth 
 * @param {bool} isForDownloading 
 * @returns {number}
 * function to determine the top and left (canvas object properties) adjustment based on
 * qr size and poster width, it only affects the qr on right side of poster 
 */
function _determineTopAndLeftAdjustment(currentTop, currentLeft, qrSize, currentPosterWidth, isForDownloading) {
    let result = { adjustedTop: currentTop, adjustedLeft: currentLeft };
    if (!qrSize) return result;

    // adjust left
    if (currentLeft > (currentPosterWidth / 2)) {
        result.adjustedLeft = currentLeft - (qrSize * (isForDownloading ? -0.15 : 0.25));
    }
    // adjust top
    if (currentTop > (currentPosterWidth / 2)) {
        result.adjustedTop = currentTop * (isForDownloading ? 1 : 0.95);
    }

    return result;
}


/**
 * 
 * @param {fabric} currentTop
 * @param {bool} isForDownloading
 */
function _adjustInsideQRTop(fabricQRImage, isForDownloading) {
    if (isForDownloading) fabricQRImage.top += 60;
    else fabricQRImage.top += 8;
}



/**
 * 
 * @param {string} imageUrl 
 * @param {number | null} size 
 * @returns {Promise<HTMLImageElement>}
 */
function _getImageElmt(imageUrl, size = null) {
    return new Promise((resolve) => {
        const imgElmt = size ? new Image(size, size) : new Image();
        imgElmt.src = imageUrl;
        imgElmt.onload = () => {
            resolve(imgElmt);
        };
    });
}

async function _createQRGroup(fabricQRImage, size = null, posterWidth, isForDownloading) {
    // const bg = isForDownloading ? BGImage : BGImageMini;
    const bg = BGImage;

    const imgElmt = await _getImageElmt(bg);
    const { left, top } = fabricQRImage;

    const fabricBG_QRImg = new fabric.Image(imgElmt, {
        label: 'QR Code Image Background',
        ...fabricDefaultsOpts
    });

    const scale = size / fabricBG_QRImg.width;
    fabricBG_QRImg.scale(scale + (isForDownloading ? 0.15 : 0.032));

    // create group
    const group = new fabric.Group([fabricBG_QRImg, fabricQRImage], {
        name: 'qr group',
        ...fabricDefaultsOpts,
        width: fabricBG_QRImg.width,
        height: fabricBG_QRImg.height
    });
    const centerPoint = fabricBG_QRImg.getCenterPoint();
    fabricQRImage.setPositionByOrigin(centerPoint, 'center', 'center');
    // this method adjust group width to fit his objects
    group.addWithUpdate();

    const { adjustedTop, adjustedLeft } = _determineTopAndLeftAdjustment(top, left, size, posterWidth, isForDownloading)
    group.top = adjustedTop;
    group.left = adjustedLeft;

    _adjustInsideQRTop(fabricQRImage, isForDownloading);

    return group;
}


async function createFabricQRImage({ size, phoneNumber, left, top, posterWidth = 800, isForDownloading = false }) {
    const _size = isForDownloading ? (size * 0.73) : size;

    try {
        // get base64 qr code image with whatsapp logo
        const base64Image = await productUtilsRequests.getWSBase64QRCode({
            phoneNumber,
            size: _size
        });

        // const qrImageElmt = await _getImageElmt(base64Image, _size + (_size * 0.08));
        const qrImageElmt = await _getImageElmt(base64Image, _size);

        const fabricQRImage = new fabric.Image(qrImageElmt, {
            left,
            top,
            label: 'QR Code Image',
            ...fabricDefaultsOpts
        });

        // return fabricQRImage;
        const qrGroup = await _createQRGroup(fabricQRImage, _size, posterWidth, isForDownloading);
        return qrGroup;

    } catch (err) {
        console.log("can't create QR Code");
        console.error(err);
        return false;
    }
}


export {
    createFabricQRImage
}