import CreateCategoryForm from './CreateCategoryForm';
import { ModalWithStepsProvider } from '../../../contexts/ModalWithStepsContext';


function CreateCategory() {
    return(
        <ModalWithStepsProvider>
            <CreateCategoryForm />
        </ModalWithStepsProvider>
    )
}

export default CreateCategory;