import { ButtonGroup, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
// requests
import categoriesRequests from '../../../request/categories';
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";


function CategoryButtonGroup({ categoryId, categoryName, noEyeBtn = false }){
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleDelete = async () => {
        const { isConfirmed } = await Swal.fire({
            title: 'Do you want to delete ' + categoryName + ' category?',
            text: 'If you delete this category ALL sub-categories of ' + categoryName +' will be deleted',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, delete',
        });

        if(isConfirmed){
            Swal.showLoading();
            let deletedMsg = await categoriesRequests.deleteCategory(categoryId);
            if(deletedMsg){
                console.log(deletedMsg);
                await Swal.fire({
                    title: 'successfully deleted',
                    icon: 'success',
                    timer: 2000
                });
                if(pathname === '/pages/categories'){
                    window.location.reload();
                }else{
                    navigate('/pages/categories');
                }
            }
        }
    }

    return (
        <ButtonGroup style={{width: noEyeBtn ? 'auto' : '100%'}}>
            {
                !noEyeBtn && (
                    <Button
                        onClick={() => navigate('/pages/category/'+ categoryId)} 
                        variant="primary"
                        className='mx-2'
                        title="View"
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                )
            }
            <Button
                onClick={() => navigate('/pages/editCategory/'+ categoryId)} 
                variant="warning"
                className={noEyeBtn ? '' : 'mx-2'}
                title="Edit"
            >
                <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
                onClick={handleDelete} 
                variant="danger"
                className='mx-2'
                title="Delete"
            >
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        </ButtonGroup>
    )
}

export default CategoryButtonGroup;