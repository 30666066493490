import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts
// import AuthLayout from "./layouts/Auth";
// import DocLayout from "./layouts/Doc";

// ********** new pages for this project (hazmoona) START **********
import DashboardLayout from "./layouts/Dashboard";
import LandingLayout from "./layouts/Landing";
// categories
import CreateCategory from "./pages/pages/categories/CreateCategory";
import Categories from "./pages/pages/categories/Categories";
import EditCategory from "./pages/pages/categories/EditCategory";
import CategoryDetail from "./pages/pages/categories/CategoryDetail";
// products
import ProductFieldsEditor from "./pages/pages/products/productFieldsEditor";
import ProductsList from "./pages/pages/products/productsList";
import CreateProduct from "./pages/pages/products/CreateProduct";
import EditProduct from "./pages/pages/products/editProduct";
// fonts
import SaveFonts from "./pages/pages/fonts/saveFonts";
import FontsList from "./pages/pages/fonts/fontsList";
// tags
import Tags from "./pages/pages/tags";
import TagCategories from "./pages/pages/tags/TagCategories";
import AutofillableFields from "./pages/pages/autofillableFields";
// shul and hall name/address
import NameAndAddressForm from "./pages/pages/shul-hall-address/addNameAndAddress";
import ShullOrHallList from "./pages/pages/shul-hall-address/shulOrHallList";
import EditShulOrHall from "./pages/pages/shul-hall-address/editShulOrHall";
// people names
import SavePeopleNames from "./pages/pages/peopleNames/savePeopleNames";
import PeopleNamesList from "./pages/pages/peopleNames/peopleNamesList";
// watermarks
import Watermarks from "./pages/pages/watermarks/watermarksIndex";
// edit customer cards
import EditCustomerCardSearch from "./pages/pages/products/purchasesEditor/editCustomerCardSearch";
import EditCustomerCardEditor from "./pages/pages/products/purchasesEditor/editCustomerCardEditor";

// temp file
import TempFile from "./temp";

// ********** new pages for this project (hazmoona) END **********

// Guards
// import AuthGuard from "./components/guards/AuthGuard";

// // Landing
// // import Landing from "./pages/landing/Landing";

// // Dashboards
// // import Default from "./pages/dashboards/Default";
// // import Analytics from "./pages/dashboards/Analytics";
// // import SaaS from "./pages/dashboards/SaaS";
// // import Social from "./pages/dashboards/Social";
// // import Crypto from "./pages/dashboards/Crypto";

// // Pages
// import Settings from "./pages/pages/Settings";
// import Clients from "./pages/pages/Clients";
// import Projects from "./pages/pages/Projects";
// import Invoice from "./pages/pages/Invoice";
// import Pricing from "./pages/pages/Pricing";
// import Tasks from "./pages/pages/Tasks";
// import Chat from "./pages/pages/Chat";
// import Blank from "./pages/pages/Blank";

// // Auth
// import Page500 from "./pages/auth/Page500";
// import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";

// // UI components
// import Alerts from "./pages/ui/Alerts";
// import Buttons from "./pages/ui/Buttons";
// import Cards from "./pages/ui/Cards";
// import Carousel from "./pages/ui/Carousel";
// import EmbedVideo from "./pages/ui/EmbedVideo";
// import General from "./pages/ui/General";
// import Grid from "./pages/ui/Grid";
// import Modals from "./pages/ui/Modals";
// import Offcanvas from "./pages/ui/Offcanvas";
// import Tabs from "./pages/ui/Tabs";
// import Typography from "./pages/ui/Typography";

// // Icons
// import Feather from "./pages/icons/Feather";
// import FontAwesome from "./pages/icons/FontAwesome";

// // Forms
// import Layouts from "./pages/forms/Layouts";
// import BasicInputs from "./pages/forms/BasicInputs";
// import InputGroups from "./pages/forms/InputGroups";
// import FloatingLabels from "./pages/forms/FloatingLabels";
// import AdvancedInputs from "./pages/forms/AdvancedInputs";
// import Formik from "./pages/forms/Formik";
// import Editors from "./pages/forms/Editors";

// // Tables
// import Tables from "./pages/tables/Tables";
// import Pagination from "./pages/tables/Pagination";
// import ColumnSorting from "./pages/tables/ColumnSorting";
// import ColumnFiltering from "./pages/tables/ColumnFiltering";
// import RowExpanding from "./pages/tables/RowExpanding";
// import RowSelection from "./pages/tables/RowSelection";

// // Charts
// import Chartjs from "./pages/charts/Chartjs";
// import ApexCharts from "./pages/charts/ApexCharts";

// // Notifications
// import Notifications from "./pages/notifications/Notifications";

// // Maps
// import GoogleMaps from "./pages/maps/GoogleMaps";
// import VectorMaps from "./pages/maps/VectorMaps";

// // Calendar
// import Calendar from "./pages/calendar/Calendar";

// // Documentation
// import Introduction from "./pages/docs/Introduction";
// import GettingStarted from "./pages/docs/GettingStarted";
// import Routing from "./pages/docs/Routing";
// import Auth0 from "./pages/docs/auth/Auth0";
// import Cognito from "./pages/docs/auth/Cognito";
// import Firebase from "./pages/docs/auth/Firebase";
// import JWT from "./pages/docs/auth/JWT";
// import Guards from "./pages/docs/Guards";
// import APICalls from "./pages/docs/APICalls";
// import Redux from "./pages/docs/Redux";
// import Internationalization from "./pages/docs/Internationalization";
// import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
// import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
// import Deployment from "./pages/docs/Deployment";
// import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
// import Support from "./pages/docs/Support";
// import Changelog from "./pages/docs/Changelog";

// // Protected routes
// import ProtectedPage from "./pages/protected/ProtectedPage";

const routes = [
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "createCategory",
        element: <CreateCategory />,
      },
      {
        path: "categories",
        element: <Categories />,
      },
      {
        path: "category/:categoryId",
        element: <CategoryDetail />,
      },
      {
        path: "editCategory/:categoryId",
        element: <EditCategory />,
      },
      {
        path: "products",
        element: <ProductsList />,
      },
      {
        path: "createProduct",
        element: <CreateProduct />,
      },
      {
        path: "editProduct/:productId",
        element: <EditProduct />,
      },
      {
        path: "editProductFields/:productId",
        element: <ProductFieldsEditor />,
      },
      {
        path: "saveFonts",
        element: <SaveFonts />,
      },
      {
        path: "fontsList",
        element: <FontsList />,
      },
      {
        path: "tags",
        element: <Tags />,
      },
      {
        path: "tagCategories",
        element: <TagCategories />,
      },
      {
        path: "autofillableFields",
        element: <AutofillableFields />,
      },
      {
        path: "saveHall",
        element: <NameAndAddressForm typeOfForm="hall" />,
      },
      {
        path: "saveShul",
        element: <NameAndAddressForm typeOfForm="shul" />,
      },
      {
        path: "halls",
        element: <ShullOrHallList typeOfList="hall" />,
      },
      {
        path: "shuls",
        element: <ShullOrHallList typeOfList="shul" />,
      },
      {
        path: "edit-hall",
        element: <EditShulOrHall />,
      },
      {
        path: "edit-shul",
        element: <EditShulOrHall />,
      },
      {
        path: "savePeopleNames",
        element: <SavePeopleNames />,
      },
      {
        path: "peopleNames",
        element: <PeopleNamesList />,
      },
      {
        path: "watermarks",
        element: <Watermarks />,
      },
      {
        path: "edit-customer-card",
        element: <EditCustomerCardSearch />,
      },
      {
        path: "edit-customer-card-editor",
        element: <EditCustomerCardEditor />,
      },

      // temp
      {
        path: "temp",
        element: <TempFile />,
      },




      // {
      //   path: "settings",
      //   element: <Settings />,
      // },
      // {
      //   path: "clients",
      //   element: <Clients />,
      // },
      // {
      //   path: "projects",
      //   element: <Projects />,
      // },
      // {
      //   path: "invoice",
      //   element: <Invoice />,
      // },
      // {
      //   path: "pricing",
      //   element: <Pricing />,
      // },
      // {
      //   path: "tasks",
      //   element: <Tasks />,
      // },
      // {
      //   path: "chat",
      //   element: <Chat />,
      // },
      // {
      //   path: "blank",
      //   element: <Blank />,
      // },
    ],
  },
  // {
  //   path: "auth",
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: "404",
  //       element: <Page404 />,
  //     },
  //     {
  //       path: "500",
  //       element: <Page500 />,
  //     },
  //   ],
  // },
  // {
  //   path: "ui",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "alerts",
  //       element: <Alerts />,
  //     },
  //     {
  //       path: "buttons",
  //       element: <Buttons />,
  //     },
  //     {
  //       path: "cards",
  //       element: <Cards />,
  //     },
  //     {
  //       path: "carousel",
  //       element: <Carousel />,
  //     },
  //     {
  //       path: "embed-video",
  //       element: <EmbedVideo />,
  //     },
  //     {
  //       path: "general",
  //       element: <General />,
  //     },
  //     {
  //       path: "grid",
  //       element: <Grid />,
  //     },
  //     {
  //       path: "modals",
  //       element: <Modals />,
  //     },
  //     {
  //       path: "tabs",
  //       element: <Tabs />,
  //     },
  //     {
  //       path: "offcanvas",
  //       element: <Offcanvas />,
  //     },
  //     {
  //       path: "typography",
  //       element: <Typography />,
  //     },
  //   ],
  // },
  // {
  //   path: "icons",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "feather",
  //       element: <Feather />,
  //     },
  //     {
  //       path: "font-awesome",
  //       element: <FontAwesome />,
  //     },
  //   ],
  // },
  // {
  //   path: "forms",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "layouts",
  //       element: <Layouts />,
  //     },
  //     {
  //       path: "basic-inputs",
  //       element: <BasicInputs />,
  //     },
  //     {
  //       path: "input-groups",
  //       element: <InputGroups />,
  //     },
  //     {
  //       path: "floating-labels",
  //       element: <FloatingLabels />,
  //     },
  //   ],
  // },
  // {
  //   path: "tables",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Tables />,
  //     },
  //   ],
  // },
  // {
  //   path: "form-plugins",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "advanced-inputs",
  //       element: <AdvancedInputs />,
  //     },
  //     {
  //       path: "formik",
  //       element: <Formik />,
  //     },
  //     {
  //       path: "editors",
  //       element: <Editors />,
  //     },
  //   ],
  // },
  // {
  //   path: "advanced-tables",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "pagination",
  //       element: <Pagination />,
  //     },
  //     {
  //       path: "column-sorting",
  //       element: <ColumnSorting />,
  //     },
  //     {
  //       path: "column-filtering",
  //       element: <ColumnFiltering />,
  //     },
  //     {
  //       path: "row-expanding",
  //       element: <RowExpanding />,
  //     },
  //     {
  //       path: "row-selection",
  //       element: <RowSelection />,
  //     },
  //   ],
  // },
  // {
  //   path: "charts",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "chartjs",
  //       element: <Chartjs />,
  //     },
  //     {
  //       path: "apexcharts",
  //       element: <ApexCharts />,
  //     },
  //   ],
  // },
  // {
  //   path: "notifications",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   path: "maps",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "google-maps",
  //       element: <GoogleMaps />,
  //     },
  //     {
  //       path: "vector-maps",
  //       element: <VectorMaps />,
  //     },
  //   ],
  // },
  // {
  //   path: "calendar",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Calendar />,
  //     },
  //   ],
  // },
  // {
  //   path: "docs",
  //   element: <DocLayout />,
  //   children: [
  //     {
  //       path: "introduction",
  //       element: <Introduction />,
  //     },
  //     {
  //       path: "getting-started",
  //       element: <GettingStarted />,
  //     },
  //     {
  //       path: "routing",
  //       element: <Routing />,
  //     },
  //     {
  //       path: "auth/auth0",
  //       element: <Auth0 />,
  //     },
  //     {
  //       path: "auth/cognito",
  //       element: <Cognito />,
  //     },
  //     {
  //       path: "auth/firebase",
  //       element: <Firebase />,
  //     },
  //     {
  //       path: "auth/jwt",
  //       element: <JWT />,
  //     },
  //     {
  //       path: "guards",
  //       element: <Guards />,
  //     },
  //     {
  //       path: "api-calls",
  //       element: <APICalls />,
  //     },
  //     {
  //       path: "redux",
  //       element: <Redux />,
  //     },
  //     {
  //       path: "internationalization",
  //       element: <Internationalization />,
  //     },
  //     {
  //       path: "environment-variables",
  //       element: <EnvironmentVariables />,
  //     },
  //     {
  //       path: "eslint-and-prettier",
  //       element: <ESLintAndPrettier />,
  //     },
  //     {
  //       path: "deployment",
  //       element: <Deployment />,
  //     },
  //     {
  //       path: "migrating-to-next-js",
  //       element: <MigratingToNextJS />,
  //     },
  //     {
  //       path: "support",
  //       element: <Support />,
  //     },
  //     {
  //       path: "changelog",
  //       element: <Changelog />,
  //     },
  //   ],
  // },
  // {
  //   path: "private",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <ProtectedPage />,
  //     },
  //   ],
  // },
  // {
  //   path: "*",
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: "*",
  //       element: <Page404 />,
  //     },
  //   ],
  // },
];

export default routes;
