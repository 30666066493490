import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { Button } from 'antd';

const WatermarkModalForm = ({ show, loading, onHide, onSubmit }) => {
    const [file, setFile] = useState(null);

    const handleChange = ev => {
        if(ev.target.files){
            setFile(ev.target.files[0]);    
        }
    }
    const handleSubmit = () => onSubmit(file);

    useEffect(() => {
        setFile(null);
    }, [show])

    return (
        <Modal show={show} onHide={onHide} size="sm">
            <Modal.Header closeButton>Add new watermark</Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label htmlFor="w-input">Upload file</Form.Label>
                    <div>
                        <input id="w-input" type="file" onChange={handleChange} accept="image/*" />
                    </div>
                </Form.Group>
                <p><b>Note:</b> works better with .svg files</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} type="primary" danger>
                    Cancel
                </Button>
                <Button type="primary" form="name-form" onClick={handleSubmit} loading={loading}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WatermarkModalForm;
