function getNameWithoutExt(nameStr) {
    if (!nameStr) return '';
    if (!nameStr.includes('.')) return nameStr;

    let strArr = nameStr.split('.');
    strArr.pop();
    return strArr.join('');
}

export {
    getNameWithoutExt
}