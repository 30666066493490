import ajax from './ajax';
import { API_BASE_URL } from '../config';

const fieldsRequests = {};

fieldsRequests.addFieldToProduct = async function (canvasObj) {
    const url = `${API_BASE_URL}/fields/AddFieldToProduct`;

    const fieldAdded = await ajax.post(url, canvasObj, { token: true });
    return fieldAdded;
}


fieldsRequests.removeFieldsFromProduct = async function (productId) {
    const url = `${API_BASE_URL}/fields/RemoveFieldsFromProduct`;

    const deleted = await ajax.post(url, { productId }, { token: true });
    return deleted;
}


fieldsRequests.getProductFields = async function (productId) {
    const url = `${API_BASE_URL}/fields/GetProductFieldsAsAdmin/${productId}`;

    const productFields = await ajax.get(url, { token: true });
    return productFields;
}


export default fieldsRequests;