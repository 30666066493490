import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPlusCircle, 
    faTextHeight, 
    faTextWidth, 
    faAlignLeft,
    faAlignRight,
    faAlignCenter 
} from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
// custom hooks
import { useFonts } from '../../../../contexts/FontsContext';
// custom components
import HrLine from "./hrLine";
import SelectFontFamily from '../selectFontFamily';
// utils
import { hasHebrewCharacters } from '../../../../utils/text';
// style
import './style/fontManipulation.css';

const specialLabels = ['date', 'parsha', 'parsha with weekday'];
const isUsefulNumber = n => {
    let _n = +n;
    if(isNaN(+_n)) return false;
    return _n !== 0;
}

const FontManipulation = ({changeFontFamily, activeCanvasObject, onChange, handleBeforeAfterText}) => {
    const  { 
        fontSize, 
        fontWeight,
        fontStyle, 
        textAlign, 
        text, 
        charSpacing, 
        lineHeight,
        fontFamily,
        label,
        beforeText,
        afterText,
        originalText
    } = activeCanvasObject;

    const [weightsAvailable, setWeightsAvailable] = useState([ 'normal' ]);
    const [currentFontHasItalic, setCurrentFontHasItalic] = useState(false);
    // const [currentFontsHasNormal, setCurrentFontHasNormal] = useState(true);
    const { fonts } = useFonts();

    useEffect(() => {
        let currentFont = fonts[fontFamily];

        if(currentFont){
            setWeightsAvailable(currentFont.weights);
            setCurrentFontHasItalic(currentFont.italics.includes(fontWeight));
            // setCurrentFontHasNormal(currentFont.weights.includes('normal'))
        }else{
            setWeightsAvailable(['normal']);
            setCurrentFontHasItalic(false);
            // setCurrentFontHasNormal(true);
        }

    }, [fontFamily, fontWeight]);


    const handleFontStyle = (ev, value) => {
        const { checked } = ev.target;

        if(value === 'italic'){
            changeFontFamily(fontFamily, fontWeight, checked ? 'italic' : 'normal' );
        }
        else if(value === 'uppercase'){
            const eventObj = { 
                target: { 
                    name: 'uppercased', value: checked
                }
            }
            onChange(eventObj);
        }
    }

    const handleTextAlign = align => {
        onChange({
            target: { name: 'textAlign', value: align }
        });
    }

    const handleLineHeight = (ev) => {
        const { value } = ev.target;
        if(isUsefulNumber(+value)){
            onChange({
                target: { name: 'lineHeight', value }
            });
        }
    }

    const isStrUppercase = () => {
        if(!text) return false;
        
        if(hasHebrewCharacters(text) || text === '') return false;

        return text === text.toUpperCase();
    }

    return (
        <>
            <div className="editor-padding">
                <div className="fm-label-btn">
                    <div className="editor-label-txt">Font</div>
                    <FontAwesomeIcon icon={faPlusCircle} color="#000" />
                </div>

                <div className="fm-container">
                    <SelectFontFamily 
                        handleFontChange={changeFontFamily} 
                        activeCanvasObject={activeCanvasObject} 
                    />

                    <div className="fm-size-weight">
                        <div className="fm-input">
                            <input 
                                type="number"
                                onChange={onChange}
                                name="fontSize"
                                value={fontSize}
                            />
                        </div>

                        <Form.Select name="fontWeight" onChange={onChange} style={{ fontWeight: fontWeight }} value={fontWeight} >
                            {
                                weightsAvailable.map( (weight, idx) => (
                                    <option key={weight+idx} value={weight} style={{ fontWeight: weight }} >{weight}</option>
                                ))
                            }
                        </Form.Select>
                    </div>



                    {/* line height and letter spacing */}
                    <div className="fm-spacing-height">
                        <div className="fm-icon-input">
                            <div className="fm-icon-input-wrapper">
                                <FontAwesomeIcon icon={faTextWidth} />
                            </div>
                            <input 
                                type="number"
                                onChange={onChange}
                                name="charSpacing"
                                value={charSpacing}
                            />
                        </div>

                        <div className="fm-icon-input">
                            <div className="fm-icon-input-wrapper">
                                <FontAwesomeIcon icon={faTextHeight} />
                            </div>
                            <input 
                                type="number"
                                onChange={handleLineHeight}
                                name="lineHeight" 
                                value={lineHeight}
                                step={0.1}
                            />
                        </div>
                    </div>

                    <div className="fm-align-and-style">
                        {/* font style */}
                        <div className="fm-style-wrapper">
                            {
                                currentFontHasItalic && (
                                    <div className="fm-style-checkbox-wrapper">
                                        <input 
                                            type="checkbox" 
                                            onChange={ev => handleFontStyle(ev, 'italic')} 
                                            id='italic-cb'
                                            checked={fontStyle === 'italic'} 
                                            // disabled={fontStyle === 'italic' && !currentFontsHasNormal}
                                        />
                                        <label htmlFor='italic-cb' style={{ fontStyle: 'italic' }}>Italic</label>
                                    </div>
                                )
                            }
                            <div className="fm-style-checkbox-wrapper">
                                <input 
                                    type="checkbox" 
                                    onChange={ev => handleFontStyle(ev, 'uppercase')} 
                                    id='uppercase-cb' 
                                    checked={isStrUppercase()}
                                />
                                <label htmlFor='uppercase-cb'>ALL CAPS</label>
                            </div>
                        </div>

                        {/* text align */}
                        <div className="fm-text-align">
                            <div 
                                onClick={() => handleTextAlign('left')} 
                                className={`fm-txt-align-icon ${textAlign === 'left' ? 'active' : ''}`}
                            >
                                <FontAwesomeIcon icon={faAlignLeft} color="#000" size='lg' />
                            </div>
                            <div 
                                onClick={() => handleTextAlign('center')} 
                                className={`fm-txt-align-icon ${textAlign === 'center' ? 'active' : ''}`}
                            >
                                <FontAwesomeIcon icon={faAlignCenter} color="#000" size='lg' />
                            </div>
                            <div 
                                onClick={() => handleTextAlign('right')} 
                                className={`fm-txt-align-icon ${textAlign === 'right' ? 'active' : ''}`}
                            >
                                <FontAwesomeIcon icon={faAlignRight} color="#000" size='lg' />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <HrLine />

            {/* before text (only dates & parsha) */}
            {
                specialLabels.includes(label) && (
                    <div className="editor-padding">
                        <div className="editor-label-txt mb-1">Before text</div>
                        <Form.Control 
                            type='text'
                            value={beforeText || ''}
                            onChange={ ev => handleBeforeAfterText(true, ev.target.value)}
                            style={{ direction: hasHebrewCharacters(text) ? 'rtl' : 'ltr' }}
                            autoComplete="off"
                        />
                    </div>
                )
            }

            {/* text content */}
            <div className="editor-padding">
                <div className="editor-label-txt mb-1">Content</div>
                <Form.Control 
                    type='text'
                    value={originalText || ''}
                    onChange={onChange}
                    name="text"
                    style={{ direction: hasHebrewCharacters(text) ? 'rtl' : 'ltr' }}
                    autoComplete="off"
                />
            </div>

            {/* after text (only dates & parsha) */}
            {
                specialLabels.includes(label) && (
                    <div className="editor-padding">
                        <div className="editor-label-txt mb-1">After text</div>
                        <Form.Control 
                            type='text'
                            value={afterText || ''}
                            onChange={ ev => handleBeforeAfterText(false, ev.target.value)}
                            style={{ direction: hasHebrewCharacters(text) ? 'rtl' : 'ltr' }}
                            autoComplete="off"
                        />
                    </div>
                )
            }

            <HrLine />
        </>
    );
}
 
export default FontManipulation;