// some utils for fabricjs
import { fabric } from 'fabric';
import { loadCustomFont } from './fontLoader';
import { hasHebrewCharacters, reverseNumbers, getOriginalText } from './text';

function setCanvasAtResoution(canvas, newWidth, originalEditorWidth, imageDimensions) {
    const scaleMultiplier = newWidth / originalEditorWidth;

    const objects = canvas.getObjects();
    for (const obj of objects) {
        obj.scaleX *= scaleMultiplier;
        obj.scaleY *= scaleMultiplier;
        obj.left *= scaleMultiplier;
        obj.top *= scaleMultiplier;
        // if (obj.label === 'QR Code') obj.width *= scaleMultiplier;
        obj.setCoords();
    }

    if (imageDimensions) {
        const scaleFactorImg = imageDimensions.width / newWidth;
        const newCanvasHeight = imageDimensions.height / scaleFactorImg;

        const imageObj = canvas.backgroundImage;
        if (imageObj) {
            imageObj.scaleX = newWidth / imageDimensions.width;
            imageObj.scaleY = newCanvasHeight / imageDimensions.height;
        }
        canvas.setHeight(newCanvasHeight);
        canvas.setWidth(newWidth);
    }

    canvas.discardActiveObject();
    canvas.renderAll();
    canvas.calcOffset();
}


function assingObjectsToCanvas(canvas, fieldsObjects = [], isForDownloading = false) {
    return new Promise((resolve) => {
        if (fieldsObjects.length === 0) return resolve(false);

        async function addToCanvas_forloop(_objects) {
            for (let obj of _objects) {
                let objToAdd;

                const defaultOpts = {
                    caching: false,
                    objectCaching: false,
                }

                if (obj.type === 'rect') {
                    objToAdd = new fabric.Rect({
                        ...obj,
                        ...defaultOpts,
                        visible: false
                    });
                }
                else if (obj.type === 'textbox') {
                    const isHebrew = hasHebrewCharacters(obj.text);
                    const text = isHebrew ? reverseNumbers(obj.text) : obj.text;

                    objToAdd = new fabric.Textbox(text, {
                        ...obj,
                        text,
                        originalText: getOriginalText(obj),
                        ...defaultOpts
                    });
                    // set again to be sure it's same position like admin site
                    objToAdd.set({
                        width: obj.width,
                        left: obj.left,
                        top: obj.top,
                        charSpacing: obj.charSpacing
                    });

                }
                else if (obj.type === 'image') {
                    let img = new Image(obj.width, obj.height);
                    img.src = obj.getSrc();

                    objToAdd = new fabric.Image(img, {
                        ...obj,
                        ...defaultOpts
                    })
                }
                else if (obj.type === 'group') {
                    let group = new fabric.Group(obj._objects, {
                        ...obj,
                        ...defaultOpts
                    });
                    group.addWithUpdate();

                    objToAdd = group;
                }

                canvas.add(objToAdd);
            }
        }

        if (!isForDownloading) {
            fabric.util.enlivenObjects(fieldsObjects, _objects => {
                addToCanvas_forloop(_objects);
                resolve(true);
            });
        }
        // just pass the array because elements are already created
        else {
            addToCanvas_forloop(fieldsObjects);
            resolve(true);
        }
    })
}

// NOTE: this function toggleAllFieldsVisibility was made by Davit but I really don't know why
/**
 * @param {fabric.Canvas} canvas
 * @param {boolean} visible
 */
export const toggleAllFieldsVisibility = (canvas, visible) => {
    const objects = canvas.getObjects();
    for (const object of objects) {
        object.oldVisible = object.oldVisible ?? object.visible;
        object.visible = visible ?? object.oldVisible;
    }
    canvas.renderAll();
}


async function setCustomFontsWhenLoaded(populatedCanvas) {
    console.log('loading custom fonts');
    try {
        const defaultFonts = ['arial', 'verdana', 'consolas', 'fantasy'];

        for (let textObj of populatedCanvas.getObjects()) {
            const { fontFamily, fontWeight, type, text, maxFontSize, maxWidth, width } = textObj;

            if (type === 'rect' || type === 'image') continue;

            if (!defaultFonts.includes(fontFamily.toLowerCase())) {
                function setFontCallback() {
                    textObj.fontFamily = fontFamily;
                    textObj.fontWeight = fontWeight;
                    // for some reason the fontFamily doesn't update when you change it.
                    // but if we change the font size it updates!
                    // idk why this happens, anyway we can change the original font size, then
                    // render, change it again and render again
                    // it's a weird solution but works
                    textObj.fontSize = maxFontSize - 1;
                    populatedCanvas.renderAll();
                    textObj.fontSize = maxFontSize;
                    window.fabric.util.clearFabricFontCache(fontFamily);

                    // in some cases with some font sizes the fabricjs TextBox doesn't load proper width
                    // for some reason it renders a bigger width, for example instead 250px width it renders 274px
                    // so with this function we force to render the original width using maxWidth property
                    // (maxWidth property was previously added when you make a save the first time and the following times)
                    if (maxWidth && (maxWidth !== width)) {
                        textObj.width = maxWidth;
                    }
                    populatedCanvas.renderAll();
                }
                await loadCustomFont({ fontFamily, fontWeight, text, callback: setFontCallback });
            }
        }
    } catch (err) {
        return;
    }
}

// force a render in case some objects aren't properly render
// i.e extra lines or extra space
function forceRenderUpdate(canvas, imageLoaded = false, inmediate = false, isDOwnloadPage = false) {
    return new Promise((resolve) => {
        setTimeout(() => {
            if (!canvas) return resolve();

            for (const obj of canvas.getObjects()) {
                const { type, fontSize } = obj;
                let extraCount = 0;

                if (type !== 'textbox') continue;

                obj.fontSize++;
                canvas.renderAll();
                obj.fontSize = fontSize;
                canvas.renderAll();

                if (imageLoaded) {
                    while (!obj.multiLine && obj._textLines.length > 1) {
                        // reduce font size 0.2%
                        obj.fontSize *= 0.998;
                        canvas.renderAll();
                        console.log('in the while single');
                    }

                    // if text is multiLine and has more lines than original, reduce fontSize to fit
                    // in original lines quantity
                    while (obj.multiLine && obj._textLines.length > obj.originalLinesLength) {
                        // reduce font size 0.2%
                        obj.fontSize *= 0.998;
                        canvas.renderAll();
                        console.log('in the while multiple');
                    }

                    if (!isDOwnloadPage) {
                        // reduce font if box width is bigger than max width (specially for multiline boxes)
                        // using a variable called "extraCount" to checking box width, because if you reduce the box width
                        // and the text is still very large (fontSize) the box width  won't change 
                        // so we need to remember the last fontSize 
                        extraCount = obj.fontSize;

                        while (obj.width > obj.maxWidth) {
                            extraCount--;
                            obj.fontSize = extraCount;
                            canvas.renderAll();
                            obj.width = obj.maxWidth;
                            canvas.renderAll();
                            console.log("reducing box width and font size to fit maxWidth");
                        }
                    }
                }

            }
            resolve();
        }, inmediate ? 0 : 3500);
    })
}

/**
 * 
 * @param {Object} canvas 
 * @returns {number}
 * get canvas width relative to fields creation on 
 * admin or resized 
 */
function getCanvasRelativeWidth(canvas) {
    let relativeWidth = 800;
    const objects = canvas.getObjects();

    if (!objects?.length) return relativeWidth;

    for (let obj of objects) {
        if (obj.canvasWidth) {
            relativeWidth = obj.canvasWidth;
            break;
        }
    }

    return relativeWidth;
}

export {
    setCanvasAtResoution,
    assingObjectsToCanvas,
    setCustomFontsWhenLoaded,
    forceRenderUpdate,
    getCanvasRelativeWidth
}