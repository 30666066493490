function hasHebrewCharacters(str) {
    if (!str) return false;

    return (/[\u0590-\u05FF]/).test(str);
}

// returns original text (apart from before and after text)
// this works for show text on "content" input
function getOriginalText(textObj) {
    const { beforeText, afterText, text, label } = textObj;
    const specialLabels = ['date', 'parsha', 'parsha with weekday'];
    let _txt = text;

    if (!specialLabels.includes(label)) return text;
    if (!text) return '';

    if (beforeText) _txt = _txt.replace(beforeText, '');
    if (afterText) _txt = _txt.replace(afterText, '');

    return _txt.trim();
}


function reverseNumbers(str) {
    const numsAndLetters = str.match(/([^0-9]|^\:)+|([0-9]|:)+/g);

    const numsRegex = /[0-9]/;

    return numsAndLetters.map((item) => (numsRegex.test(item) ? item.split("").reverse().join("") : item)).join('');
}

export {
    hasHebrewCharacters,
    getOriginalText,
    reverseNumbers
}