import React from "react";
import { Modal, Spinner } from 'react-bootstrap';


function ModalStepsLoader({ currentStepText, show }) {
    return (
        <Modal show={show} onHide={() => false} backdrop="static" >
            <Modal.Body className="text-center m-3">
                <h3 className="h3 mb-4">{currentStepText}</h3>
                <Spinner animation="border" />
            </Modal.Body>
        </Modal>
    )
}

export default ModalStepsLoader;