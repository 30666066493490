import React, { useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';
import { Button } from 'antd';
import PhoneInput, { isPossiblePhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
// utils
import { createFabricQRImage } from '../../../../utils/createFabricQRImage';
import { nanoid } from 'nanoid';
// styles
import 'react-phone-number-input/style.css'


const QrCodeEditor = ({ canvas, qrText, setQRText }) => {
    const [inputValue, setInputValue] = useState(qrText);
    const [showTooltip, setShowTooltip] = useState(false);  
    const [loading, setLoading] = useState(false);  

    const removeQrCode = () => {
        const qrImageGroup = canvas.getObjects().find( obj => obj.name === 'qr group');
        const group = canvas.getObjects().find( obj => obj.type === 'group');
        const objToDelete = qrImageGroup || group;

        if(objToDelete) {
            canvas.remove(objToDelete);
            canvas.renderAll();
        };
    }


    const handleChange = value => {
        setShowTooltip(false);
        if(!value) return;

        if(isPossiblePhoneNumber(value)){
            const fullPhone = formatPhoneNumberIntl(value);
            setInputValue(value);
            setQRText(fullPhone);
        }
    }

    const handleGenerateQR = async (onLoadText = "") => {
        const inputText = (onLoadText || inputValue).replace(/ /g, "");
        const phoneRegex = /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/;
        if(!phoneRegex.test(inputText)){
            console.log('qr value not valid');
            setShowTooltip(true);
            return;
        }
        setLoading(true);


        const fabricQRGroup = await createFabricQRImage({ 
            size: 85,
            left: 30,
            top: 30,
            phoneNumber: inputText,
            posterWidth: 800,
            isForDownloading: false
        })
        setLoading(false);

         // remove previous qr code
        removeQrCode();
        fabricQRGroup.tempId = nanoid(10);
        canvas.add(fabricQRGroup);
        canvas.renderAll();

        setTimeout(() => {
            canvas.renderAll();
        }, 25);
    }

    useEffect(() => {
        let clean = (qrText || "").replace(/ /g, "");
        setInputValue(clean);
        if(clean){
            handleGenerateQR(clean);
        }
    }, [qrText]);



    return (
        <div className="editor-padding">
            <div className="editor-label-txt">QR Code</div>

            <div>
                <PhoneInput
                    defaultCountry='US'
                    value={inputValue}
                    id='input-qr-elmt'
                    autoComplete='off'
                    onChange={handleChange}
                    placeholder="Enter phone number"
                />
                <Button 
                    type="primary" 
                    id='btn-qr' 
                    loading={loading}
                    style={{marginTop: '7px'}}
                    onClick={() => handleGenerateQR(null)}>
                        Generate qr code
                </Button>
                <Tooltip 
                    target='input-qr-elmt'
                    placement='left'
                    isOpen={showTooltip}
                >
                    Please write a valid phone format
                </Tooltip>
            </div>
        </div>
    );
}
 
export default QrCodeEditor;