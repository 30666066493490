import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Spinner } from 'react-bootstrap';
import { Button } from 'antd';
// import { DownloadOutlined, EditFilled } from '@ant-design/icons';

// requests
import customerPurchasesRequests from '../../../../request/customerPurchases';
import './style/purchasesListItem.css';


const PurchasedItem = (props) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const {wooOrderItemId, wooCartItemKey, orderId} = props;
        customerPurchasesRequests.getWooOrderItem({
            wooCartItemKey,
            wooOrderItemId,
            wooOrderId: orderId
        }).then( _data => {
            const { customized_item_preview, customization_data } = _data;
            const creationDate = props.creationDateMS ? new Date(props.creationDateMS).toLocaleDateString() : null;

            setData({
                productName: customization_data.productName,
                preview: customized_item_preview,
                hasQR: !!customization_data.QRText,
                hasSong: !!customization_data.song,
                fullData: _data,
                customerEmail: props.customerEmail || null,
                creationDate,
            })
            setLoading(false);
        })
    }, []);

    const gotoEditPage = () => {
        navigate('/pages/edit-customer-card-editor', {
            state: data
        });
    }

    // const handleDownloadModal = () => {
    //     console.log('download modal');
    // }

    return (
        <Card>
            <Card.Body>
                {
                    loading ? <Spinner animation="border" variant="primary" /> : (
                        // pi => purchased item
                        <div className="pi">
                            <div className="pi-top-wrapper">
                                <div className="pi-left">
                                    <img src={data.preview} alt="thumbnail" />
                                </div>
                                <div className="pi-right">
                                    <div className="pi-detail title">{data.productName}</div>
                                    <div className="pi-detail">QR: {data.hasQR ? 'Yes' : 'No'}</div>
                                    <div className="pi-detail">Song: {data.hasSong ? 'Yes' : 'No'}</div>
                                    { props.customerEmail && <div className="pi-detail">Associated email: {props.customerEmail}</div> }
                                    { data.creationDate && <div className="pi-detail">Created at: {data.creationDate}</div> }
                                </div>
                            </div>
                            <div className="pi-bottom-wrapper">
                                <Button onClick={gotoEditPage} type="primary">Edit and download</Button>
                                {/* <Button onClick={handleDownloadModal} type="primary" color="#AF40F7" icon={<DownloadOutlined/>} style={{ marginLeft: '10px' }}>Download</Button> */}
                            </div>
                        </div>
                    )
                }

            </Card.Body>
        </Card>
    );
}
 
export default PurchasedItem;