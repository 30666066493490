function FullWidthRow({ children, colSpan = 0 }) {
    return (
        <tr>
            <td colSpan={colSpan}>
                {children}
            </td>
        </tr>
    )
}

export default FullWidthRow;