import { fabric } from 'fabric';
import { getOriginalText } from '../../../utils/text';

//variables for undo/redo
let pause_saving = false;
let undo_stack = [];
let redo_stack = [];
let lastTempId = '';


const eventsNames = [
    'object:added',
    'object:modified',
    'object:removed',
    'fill:change',
    'fontWeight:change',
    'fontFamily:change',
    'textAlign:change',
    'object:rotate',
    'alignment:change',
    'group:created'
    // 'text:change',
]


function addEventsToCanvas(canvas) {
    // remove previous events
    for (let eventName of eventsNames) {
        delete canvas.__eventListeners[eventName];
    }

    // listen for changes in the canvas
    for (let eventName of eventsNames) {
        canvas.on(eventName, function (ev) {
            if (!pause_saving) {
                undo_stack.push(canvasToJsonStr(canvas));
                redo_stack = [];
                setLastTempId(canvas);
                console.log(eventName);
                if (eventName === 'object:modified') {
                    console.log(ev);
                }
            }
        });
    }
    // on active object(s) cleared
    // canvas.on('before:selection:cleared', () => {

    // });
}


function checkGroupInfo(canvas) {
    let groupInfo = { hasGroup: false, groupElements: {} }
    const activeObjs = canvas.getActiveObjects();

    if (activeObjs.length >= 2) {
        groupInfo.hasGroup = true;

        const group = activeObjs[0].group;
        let groupLeft = group.left
        let groupTop = group.top
        // calculate real canvas position for objects inside group
        for (let obj of activeObjs) {
            let objectInGroupLeft = obj.left + groupLeft + group.width / 2;
            let objectInGroupTop = obj.top + groupTop + group.height / 2;

            groupInfo.groupElements[obj.tempId] = {
                left: objectInGroupLeft,
                top: objectInGroupTop
            }
        }
    }
    // window.groupInfo = groupInfo;
    return groupInfo;
}


function canvasToJsonStr(canvas) {
    const objs = [];
    const groupInfo = checkGroupInfo(canvas);

    // console.log(JSON.stringify(groupInfo));

    for (const originalObj of canvas.getObjects()) {
        const { label, autocompleteLabel, productId, tempId, lockScalingY, borderColor, _id, beforeText, afterText, indexOrder } = originalObj;
        // for some reason when you want to spread objects (...{}) from canvas.getObjects()
        // the properties you added are missing, so a fix for this is copy the original object array
        let _obj = JSON.parse(JSON.stringify(originalObj));

        if (_obj.top < 0) {
            console.log(`\nTOP < 0 !!! ${_obj.top} ${_obj.text}\n`);
        }

        objs.push({
            ..._obj,
            _id,
            label,
            autocompleteLabel,
            productId,
            tempId,
            lockScalingY,
            borderColor,
            indexOrder,
            originalText: getOriginalText(originalObj),
            beforeText: beforeText || '',
            afterText: afterText || '',
        })
    }

    return JSON.stringify({
        canvasObjects: objs,
        groupInfo
    });
}

// TODO: work with group and single objects
function setLastTempId(canvas) {
    const activeObj = canvas.getActiveObject();
    if (activeObj) {
        if (activeObj.type !== 'activeSelection') {
            lastTempId = activeObj.tempId;
        }
    }
}



function undoRedoController2({
    canvas,
    callbackPerTxtObj,
    onStateRecovered
}) {
    console.log('##### undo/redo init #####');
    if (!canvas) console.log('\nNOT CANVAS!');

    addEventsToCanvas(canvas);



    // delete objects and add saved state
    function printSavedState(savedState) {
        const { canvasObjects, groupInfo } = JSON.parse(savedState);

        if (!canvas) console.log('\nNOT CANVAS! 0')
        canvas.discardActiveObject();
        canvas.renderAll();

        addEventsToCanvas(canvas);

        // delete all current canvas objects
        for (let obj of canvas.getObjects()) canvas.remove(obj);

        fabric.util.enlivenObjects(canvasObjects, objs => {
            // add objects to canvas
            objs.forEach(obj => {
                let objToAdd;
                let _left = obj.left, _top = obj.top;

                // check for real position if is inside group
                if (groupInfo.groupElements[obj.tempId]) {
                    let { left, top } = groupInfo.groupElements[obj.tempId];
                    _left = left;
                    _top = top;
                }

                if (obj.type === 'rect') {
                    objToAdd = new fabric.Rect({ ...obj });
                    objToAdd.set({
                        text: 'QR Code',
                        left: _left,
                        top: _top
                    });
                }
                else {
                    objToAdd = new fabric.Textbox(obj.text, {
                        ...obj
                    });
                    objToAdd.set({
                        text: obj.text,
                        left: _left,
                        top: _top
                    });
                }

                canvas.add(objToAdd);

                // add some events
                callbackPerTxtObj(objToAdd);
            });

            if (!canvas) console.log('\nNOT CANVAS! 1')

            // recreate group
            if (groupInfo.hasGroup) {
                const selectedObjs = canvas.getObjects().filter(obj => groupInfo.groupElements[obj.tempId] ? true : false);
                const selection = new fabric.ActiveSelection(selectedObjs, { canvas });
                canvas.setActiveObject(selection);
            }
            else {
                const lastActiveObj = canvas.getObjects().find(obj => obj.tempId === lastTempId);
                if (lastActiveObj) {
                    canvas.setActiveObject(lastActiveObj);
                }
            }


            if (!canvas) console.log('\nNOT CANVAS! 2')

            canvas.renderAll();

            onStateRecovered(canvas.getObjects());
        });
    }


    function keyboardListener(event) {
        //Undo - CTRL+Z
        if (event.ctrlKey && event.code === 'KeyZ') {
            pause_saving = true;

            redo_stack.push(undo_stack.pop());

            let previous_state = undo_stack[undo_stack.length - 1];

            if (previous_state) {
                printSavedState(previous_state);
            }
            pause_saving = false;
        }

        //Redo - CTRL+Y
        else if (event.ctrlKey && event.code === 'KeyY') {
            pause_saving = true;
            let state = redo_stack.pop();

            if (state) {
                undo_stack.push(state);

                printSavedState(state);
            }

            pause_saving = false;
        }
    }

    // remove previous listener
    window.removeEventListener('keyup', keyboardListener);

    //Listen for undo/redo 
    window.addEventListener('keyup', keyboardListener);
}


export {
    undoRedoController2
}