import { useState, useEffect } from 'react';
import availableFields from '../availableFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faEyeSlash, faClone } from '@fortawesome/free-solid-svg-icons';
// drag and drop
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'
// custom components
import ChangeLabelDropdown from './changeLabelDropdown';
// utils
import { hasHebrewCharacters } from '../../../../utils/text';
// style
import './style/addedFields.css';

// save label colors
const labelColors = availableFields.reduce((plainObj, field) => { 
    plainObj[field.label] = field.borderColor;
    return plainObj; 
}, {})


const AddedFields = ({ 
    canvasObjects, 
    activeObj, 
    onDelete, 
    onDuplicate,
    onViewToggle, 
    onShowSelected,
    onLabelChange, 
    setActiveCanvasObjectStyles,
    isEditPurchasePage = false
}) => {
    const [fieldsObjs, setFieldsObjs] = useState([]);

    useEffect(() => {
        const objs = canvasObjects || [];
        const objsCopy = [...objs];
        objsCopy.sort((obj1, obj2) => obj1.indexOrder < obj2.indexOrder ? -1 : 1 );
        setFieldsObjs(objsCopy);

    }, [canvasObjects]);

    const onSortEnd = (oldIndex, newIndex) => {
        const currentObjMoved = fieldsObjs[oldIndex];

        setFieldsObjs((prevState) => {
            const modifiedArrayObjs = arrayMoveImmutable(prevState, oldIndex, newIndex);
            // set indexOrder for canvas objects
            for (let i = 0; i < modifiedArrayObjs.length; i++) {
                const { tempId } = modifiedArrayObjs[i];
                const idx = canvasObjects.findIndex( obj => obj.tempId === tempId);
                if(idx !== -1){
                    canvasObjects[idx].indexOrder = i;
                }
            }

            return modifiedArrayObjs;
        });

        setActiveCanvasObjectStyles(currentObjMoved);
    }

    return (
        <div>
            <div className="editor-padding">
                <div className="editor-label-txt mb-3">Added Fields</div>

                <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
                {
                    fieldsObjs.map((txtObj) => (
                        (isEditPurchasePage && txtObj.label === 'QR Code') ? (
                            null
                        ) : (
                            <SortableItem key={txtObj.tempId}>
                                <div 
                                    className={`af-wrapper ${activeObj.tempId === txtObj.tempId ? 'active' : ''}`} 
                                    onClick={() => onShowSelected(txtObj.tempId)}
                                >
                                    <div className="af-left" title={txtObj.label}>
                                        <div className="af-color" style={{ backgroundColor: labelColors[txtObj.label] }} />
                                        <div 
                                            className="af-text"
                                            style={{ direction: hasHebrewCharacters(txtObj.text) ? 'rtl' : 'ltr'}}
                                        >
                                            {txtObj.text || txtObj.type}
                                        </div>
                                        {
                                            (activeObj.tempId === txtObj.tempId) && (
                                                <div className="af-current-obj" />
                                            )
                                        }
                                    </div>
                                    <div className="af-right">
                                        <div className="af-btn af-btn-eye" onClick={() => onViewToggle(txtObj.tempId)}>
                                            <FontAwesomeIcon icon={ txtObj.visible ? faEye : faEyeSlash } />
                                        </div>
                                        <div className="af-btn" onClick={() => onDelete(txtObj.tempId)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </div>
                                        {
                                            txtObj.type === 'group' ? (
                                                null
                                            ) : (
                                                (txtObj.label !== 'QR Code') && (
                                                    <>
                                                        <div className="af-btn af-btn-copy" onClick={() => onDuplicate(txtObj.tempId)} title="Duplicate">
                                                            <FontAwesomeIcon icon={faClone} />
                                                        </div>
                                                        
                                                        <ChangeLabelDropdown 
                                                            tempId={txtObj.tempId} 
                                                            onLabelChage={onLabelChange}
                                                        />
                                                    </>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </SortableItem>
                        )
                    ))
                }
                </SortableList>
            </div>
        </div>
    );
}
 
export default AddedFields;