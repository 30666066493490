import ajax from './ajax';
import { API_BASE_URL } from '../config';

const filesRequests = {};

// image
filesRequests.uploadImage = async function (imageFile) {
    const url = `${API_BASE_URL}/files/SaveImage`;

    const formData = new FormData();
    formData.append('image', imageFile);

    const savedImage = await ajax.post(url, formData, { token: true });
    return savedImage;
}


filesRequests.uploadProductImage = async function (imageFile, watermarkId) {
    const url = `${API_BASE_URL}/files/SaveProductFile`;

    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('watermarkId', watermarkId);

    const imageSavedData = await ajax.post(url, formData, { token: true });
    return imageSavedData;
}

filesRequests.getOriginalImage = async function (fileId) {
    const url = `${API_BASE_URL}/files/OriginalImage/${fileId}`;

    const imageUrl = await ajax.get(url);
    return imageUrl;
}

// songs
filesRequests.uploadSongs = async function (songsFiles, songsNames) {
    const url = `${API_BASE_URL}/files/SaveSongs`;

    const formData = new FormData();
    for (let file of songsFiles) {
        formData.append('songs', file);
    }
    formData.append('songsNames', JSON.stringify(songsNames));

    const savedSongs = await ajax.post(url, formData, { token: true });
    return savedSongs;
}

// font
filesRequests.uploadFont = async function ({ fontFile, fontName, fontWeight }) {
    const url = `${API_BASE_URL}/files/SaveFont`;

    const formData = new FormData();
    formData.append('font', fontFile);
    formData.append('name', fontName);
    formData.append('weight', fontWeight);

    const savedFont = await ajax.post(url, formData, { token: true });
    return savedFont;
}


filesRequests.deleteFont = async function (fontId) {
    const url = `${API_BASE_URL}/files/DeleteFont`;

    const deletedFont = await ajax.post(url, { _id: fontId }, { token: true });
    return deletedFont;
}


filesRequests.getAllFonts = async function () {
    const url = `${API_BASE_URL}/files/GetAllFonts`;

    const fonts = await ajax.get(url);
    return fonts;
}

filesRequests.getFontByNameAndWeight = async function (name, weight) {
    const url = `${API_BASE_URL}/files/getFontByNameAndWeight?name=${name}&weight=${weight}`;

    const fonts = await ajax.get(url);
    return fonts;
}

// watermarks

filesRequests.saveWatermark = async function (imageFile) {
    const url = `${API_BASE_URL}/files/SaveWatermark`;

    const formData = new FormData();
    formData.append('image', imageFile);

    const imageSavedData = await ajax.post(url, formData, { token: true });
    return imageSavedData;
}

filesRequests.deleteWatermark = async function (fileId) {
    const url = `${API_BASE_URL}/files/DeleteWatermark`;

    const deleted = await ajax.post(url, { _id: fileId }, { token: true });
    return deleted;
}

filesRequests.watermarks = async function () {
    const url = `${API_BASE_URL}/files/watermarks`;

    const result = await ajax.get(url, { token: true });
    return result;
}

filesRequests.changeWatermark = async function (fileId, watermarkId) {
    const url = `${API_BASE_URL}/files/changeWatermark`;

    const result = await ajax.post(url, { fileId, watermarkId }, { token: true });
    return result;
}


filesRequests.editFile = async function (fileId, newBody) {
    const url = `${API_BASE_URL}/files/EditFile`;

    const result = await ajax.post(url, { fileId, newBody }, { token: true });
    return result;
}


export default filesRequests;