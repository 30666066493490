import EditCategoryForm from './EditCategoryForm';
import { ModalWithStepsProvider } from '../../../contexts/ModalWithStepsContext';


function EditCategory() {
    return(
        <ModalWithStepsProvider>
            <EditCategoryForm />
        </ModalWithStepsProvider>
    )
}

export default EditCategory;