import React, { useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import { WithContext as ReactTags } from 'react-tag-input';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
// custom components
import { SimplePageTitle } from '../../../components/titles/pageTitles';
// requests
import autocompleteRequests from '../../../request/autocompleteValues';


const SavePeopleNames = () => {
    const [tags, setTags] = useState([]);
    const navigate = useNavigate();

    const handleAddTag = tag => {
		setTags([...tags, tag]);
	}


	const handleDeleteTag = idx => {
		setTags(tags.filter((_, i) => i !== idx));
	}


    const handleSubmit = async ev => {
        ev.preventDefault();
        if(!tags.length) return;
        
        Swal.showLoading();

        for(let t of tags){
            await autocompleteRequests.saveAutocompleteValue({
                autocompleteLabel: 'person name',
                value: t.text
            })
        }

        await Swal.fire({
            title: 'Saved',
            text: 'Redirecting to names list',
            icon: 'success',
            timer: 2000
        });

        navigate('/pages/peopleNames');
    }


    return (
        <div>
            <SimplePageTitle>Add people names</SimplePageTitle>

            <Card>
				<Card.Body>
					<Form onSubmit={handleSubmit} id="productForm">
                        <Form.Group className="mb-3">
							<Form.Label>Add names separated by comma (,) or pressing Enter</Form.Label>
							<ReactTags
								tags={tags}
								delimiters={[188, 13]} // comma and enter keys
								handleDelete={handleDeleteTag}
								handleAddition={handleAddTag}
								inputFieldPosition="top"
							/>
						</Form.Group>

						<Button type="submit" size="lg" variant="primary">Save Names</Button>
					</Form>
				</Card.Body>
			</Card>
        </div>
    );
}
 
export default SavePeopleNames;