import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Spinner } from 'react-bootstrap';
import { Switch } from 'antd'
import Swal from 'sweetalert2';
// requests
import autocompleteRequests from '../../../request/autocompleteValues';
// custom components
import { SimplePageTitle } from '../../../components/titles/pageTitles';
import SimpleButtonLink from '../../../components/Links/simpleButtonLink';
// custom hooks
import { useIsMounted } from '../../../hooks/useIsMounted';

import 'antd/lib/switch/style/css';

const ShulOrHallList = ({typeOfList}) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const isMounted = useIsMounted();
    const navigate = useNavigate();


    useEffect(() => {
        autocompleteRequests.getAllAutocompleteValues(typeOfList)
            .then( _data => {
                if(isMounted.current) setData(_data)
            })
            .catch(console.error)
            .finally(() => {
                if(isMounted.current) setLoading(false);
            })
    }, [typeOfList]);


    const handleApprove = async (item, checked) => {
        await autocompleteRequests.updateAutocompleteValue({
            ...item,
            approved: checked
        });
        // update state
        let dataCopy = [...data];
        let itemToUpdate = dataCopy.find( i => i._id === item._id);
        itemToUpdate.approved = checked;
        setData(dataCopy);
    }


    const handleDelete = async (item) => {
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete ' + item.value + '?',
            confirmButtonText: 'Yes, delete',
            cancelButtonText: 'No, cancel',
            showCancelButton: true,
            icon: 'warning'
        });

        if(isConfirmed){
            Swal.showLoading();

            await autocompleteRequests.deleteAutocompleteValue(item);
            const newData = await autocompleteRequests.getAllAutocompleteValues(typeOfList);

            await Swal.fire({
                title: 'Deleted',
                icon: 'success',
                timer: 1500
            });
            setData(newData);
        }
    }


    const handleEdit = (item) => {
        navigate(`/pages/edit-${typeOfList}`, {
            state: {
                item,
                typeOfEdit: typeOfList
            }
        })
    }


    return (
        <div>
            <div className="d-flex justify-content-between">
                <SimplePageTitle>
                    <b>{typeOfList}</b> list
                </SimplePageTitle>

                <SimpleButtonLink 
                    to={`/pages/${typeOfList === 'hall' ? 'saveHall' : 'saveShul'}`}
                    title={`Add new ${typeOfList}`}
                    className="float-right"
                />
            </div>

            { isLoading && <Spinner size='lg' animation='border' />}

            <Table>
                <thead>
                    <tr>
                        <th>{typeOfList} name</th>
                        <th>{typeOfList} address</th>
                        <th>date added</th>
                        <th>Added by admin</th>
                        <th>is approved</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data.length > 0 ? (
                        data.map(item => (
                            <tr key={item._id}>
                                <td>{item.value}</td>
                                <td>{item.associatedValue?.value || 'N/A'}</td>
                                <td>{item.createdAt}</td>
                                <td>{item.createdByAdmin ? 'Yes' : 'No'}</td>
                                <td>
                                    <Switch
                                        checked={item.approved}
                                        onChange={(checked) => handleApprove(item, checked)}
                                    /> 
                                </td>
                                <td style={{display: 'flex'}}>
                                    <Button onClick={() => handleEdit(item)} variant="warning">
                                        Edit
                                    </Button>
                                    <Button onClick={() => handleDelete(item)} variant="danger" className='mx-2'>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6} className='text-center'>There are no {typeOfList}s</td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
        </div>
    );
}
 
export default ShulOrHallList;